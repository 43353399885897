<!--
 * @Descripttion: 设置
 * @Date: 2023-12-07 14:38:31
 * @Author: SYC
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-20 22:01:45
-->
<template>
    <div class="container_component"  v-loading="loading">
        <el-tabs v-model="titleName"  @tab-click="handleClick"  type="border-card" style="height: 100%;">
          <!-- <el-tab-pane name="print" label="默认打印机设置">
            <el-table
              border
              :data="tableData"
              style="width: 100%">
              <el-table-column
                type="index"
                label="编号"
                width="50">
              </el-table-column>
              <el-table-column
                label="打印机名称"
                >
              </el-table-column>
              <el-table-column
                prop="name"
                label="是否默认"
                >
              </el-table-column>
              <el-table-column
                label="是否备用">
              </el-table-column>
              <el-table-column
                label="操作"
                width="180"
                >
              </el-table-column>
            </el-table>
          </el-tab-pane> -->
          <el-tab-pane name="opinion" label="意见反馈">
            <div class="opinion_top df_c_s">
              <div class="opinion_title">反馈记录</div>
              <div class="right">
                <el-button round @click="addOpinion" type="primary" icon="el-icon-plus">新增反馈</el-button>
              </div>
            </div>
            <el-table
              border
              :data="opinionForm"
              style="width: 100%">
              <el-table-column
                prop="id"
                width="50"
                label="ID"
                >
              </el-table-column>
              <el-table-column
                label="反馈内容"
                prop="content"
                >
              </el-table-column>
              <el-table-column
                prop="feedback_time"
                label="反馈时间"
                >
              </el-table-column>
              <el-table-column
                label="状态"
                prop="feedback_time"
                >
                <template>
                  <div >未回复</div>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="180"
                > 
                <template>
                  <el-button disabled type="text">查看</el-button>
                </template>
              </el-table-column>
              </el-table>

          </el-tab-pane>
          <el-tab-pane name="vote" label="票面设置">
            <el-tabs v-model="activeName" @tab-click="voteClick">
              <el-tab-pane label="竞彩" name="jingcai">
                <el-form ref="form" :model="colorForm" label-width="120px">
                  <el-form-item label="订单号前缀">
                    <el-input
                    style="width: 300px;margin-right: 20px;" v-model="colorForm.first_coding"
                    placeholder="请输入"
                    ></el-input>
                    <el-input type="text" maxlength="8" style="width: 100px;margin-right: 20px;" v-model="colorForm.second_coding"
                    placeholder="请输入"
                    ></el-input>
                    <el-input type="text" maxlength="8" style="width: 100px;" v-model="colorForm.thirdly_coding"
                    placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="特殊资源">
                    <el-radio-group v-model="colorForm.print_versions">
                      <el-radio :label="1">地址模板</el-radio>
                      <el-radio label="2" disabled >广告版一</el-radio>
                      <el-radio label="3" disabled >广告版二</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="店铺地址">
                    <el-input style="width: 200px;" v-model="colorForm.lottery_add"></el-input>
                  </el-form-item>
                  <el-form-item label="底部编码">
                    <el-input style="width: 200px;" v-model="colorForm.fourthly_coding"></el-input>
                  </el-form-item>
                  <el-form-item  label="二维码11">
                    <el-upload action=""   :limit="1" 
                    :on-change="handleelchange"  
                    :auto-upload="false" 
                    list-type="picture-card">
                      <i class="el-icon-plus"></i>
                  </el-upload>
                    <span class="ava_text">建议图片尺寸: 1511*318,png格式</span>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="sumColorForm" round>保存</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="北单" name="beidan">
                <el-form ref="form" :model="colorForm" label-width="120px">
                  <el-form-item label="订单号前缀">
                    <el-input  type="text" maxlength="6" style="width: 100px;" 
                    v-model="colorForm.first_coding"
                    placeholder="请输入"
                   
                    ></el-input>
                    <span> - </span>
                    <el-input  type="text" maxlength="6" style="width: 100px;" v-model="colorForm.second_coding"
                    placeholder="请输入"
                    ></el-input>
                    <span> - </span>
                    <el-input  type="text" maxlength="6" style="width: 100px;" v-model="colorForm.thirdly_coding"
                    placeholder="请输入"
                    ></el-input>
                    <span> - </span>
                    <el-input  type="text" maxlength="6" style="width: 100px;" v-model="colorForm.fourthly_coding"
                    placeholder="请输入"
                    ></el-input>
                    <span> - </span>
                    <el-input  type="text" maxlength="13" style="width: 180px;" v-model="colorForm.fifth_coding"
                    placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="特殊资源">
                    <el-radio-group v-model="colorForm.resource">
                      <el-radio :label="1">地址模板</el-radio>
                      <el-radio label="2" disabled>广告版一</el-radio>
                      <el-radio label="3" disabled>广告版二</el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="店铺地址">
                    <el-input style="width: 200px;" v-model="colorForm.lottery_add"></el-input>
                  </el-form-item>

                  <el-form-item label="底部编码">
                    <el-input style="width: 200px;" v-model="colorForm.fourthly_coding"></el-input>
                  </el-form-item>
                  <el-form-item  label="二维码">
                   
                    <span class="ava_text">建议图片尺寸: 1511*318,png格式</span>
                  </el-form-item>

                  <el-form-item>
                    <el-button type="primary" round  @click="sumColorForm">保存</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="排三" name="paisan">
                <el-form ref="form" :model="colorForm" label-width="120px">
                  <el-form-item label="订单号前缀">
                    <el-input type="text" maxlength="6" style="width: 100px;" v-model="colorForm.first_coding"
                    placeholder="请输入"
                    ></el-input>
                    <span> - </span>
                    <el-input type="text" maxlength="6" style="width: 100px;" v-model="colorForm.second_coding"
                    placeholder="请输入"
                    ></el-input>
                    <span> - </span>
                    <el-input type="text" maxlength="6" style="width: 100px;" v-model="colorForm.thirdly_coding"
                    placeholder="请输入"
                    ></el-input>
                    <span> - </span>
                    <el-input type="text" maxlength="6" style="width: 100px;margin-right: 20px;" v-model="colorForm.fourthly_coding"
                    placeholder="请输入"
                    ></el-input>
                    <el-input type="text" maxlength="6" style="width: 100px;margin-right: 20px;" v-model="colorForm.fifth_coding"
                    placeholder="请输入"
                    ></el-input>
                    <el-input type="text" maxlength="6" style="width: 100px;" v-model="colorForm.sixth_coding"
                    placeholder="请输入"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="特殊资源">
                    <el-radio-group v-model="colorForm.resource">
                      <el-radio label="1">地址模板</el-radio>
                      <el-radio label="2">广告版一</el-radio>
                      <el-radio label="3">广告版二</el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="店铺地址">
                    <el-input style="width: 200px;" v-model="colorForm.lottery_add"></el-input>
                  </el-form-item>

                  <el-form-item label="底部编码">
                    <el-input style="width: 200px;" v-model="colorForm.fourthly_coding"></el-input>
                  </el-form-item>
                  <el-form-item  label="二维码">
                    <span class="ava_text">建议图片尺寸: 1511*318,png格式</span>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" round  @click="sumColorForm">保存</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
      </el-tabs>
      <el-dialog
        title="反馈意见"
        :visible.sync="opinionShow"
        width="600px"
        center>
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入您要反馈的问题"
          v-model="opinionText">
        </el-input>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" round @click="addFeedback">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
import {getCodingGain, getFeedbackList, 
  getChromaticaCoding,
  addSubmitFeedback,
  updateChromaticaCoding,
  updateImg
} from "@/utils/api.js";
export default {
  props: {
    printHtml: "",
  },
  data() {
    return {
      tableData: [],
      opinionShow: false,
      opinionText: '',
      activeName: 'jingcai',
      colorForm: {          // 竞彩模板
      },
      opinionForm: [],      // 意见反馈
      beodanForm: {

      },
      titleName: 'opinion',
      loading: false,
      imageUrl: '',
      dialogVisible: false
    };
  },
  mounted()  {
    // this.getVoteForm(1, 1)
    this.getOpinionForm()
  },
  methods: {
    getprintForm(type) {
      this.loading = true
      let that = this
      getChromaticaCoding({type}).then(res => {
        that.loading = false
        that.colorForm = res.data
      })
    },
    sumColorForm() {
      let that = this
      updateChromaticaCoding(this.colorForm).then(res => {
        if(res.code == 200) {
          that.$message({
            type: 'success',
            message: '修改成功!'
          });
        }else {
          that.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
    // 获取意见反馈
    getOpinionForm() {
      getFeedbackList({}).then(res => {
        this.opinionForm = res.data
      })
    },
    addFeedback() {
      if(this.opinionText == null || this.opinionText == '') {
        that.$message({
          type: 'error',
          message: '请输入您要反馈的问题!'
        });
        retrun
      }
      addSubmitFeedback({
        content: this.opinionText
      }).then(res => {
        this.$message({
          type: 'success',
          message: '反馈成功'
        });
        this.opinionShow = false
        this.getOpinionForm()
      })
    },

    // 获取片面数据
    getVoteForm(type, versions ) {
      let that = this
      getCodingGain({
        type,
        print_versions: versions
      }).then(res => {
        that.colorForm = res.data
      })
    },
    addOpinion() {
      this.opinionShow = true
    }, 
    handleAvatarSuccess(file) {
    },
    fuccessFile(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.imageUrl = file.url;
      this.dialogVisible = true;
    },
    imgSuccess() {
      this.fileList = fileList;
    },
    handleRemove(file) {
      this.dialogVisible = false;
    },


    handleClick(tab, event) {
      this.activeName = 'jingcai'
      if(this.titleName == 'vote') {
        this.getprintForm(1)
      }
    },
    voteClick() {
      if(this.activeName == 'jingcai') {
        this.getprintForm(1)
      }else if(this.activeName == 'beidan') {
        this.getprintForm(2)
      }else if(this.activeName == 'paisan') {
        this.getprintForm(3)
      }
    },
    handleelchange(file,fileList) {
      console.log(fileList);
      let formData = new FormData()

      formData.append("image", fileList[0].raw)  //将每一个文件图片都加进formdata
      formData.append('path', 'upload')

      console.log(formData.get('path'));
      console.log(formData.get('image'));

      console.log(formData);
      updateImg(
        formData
      ).then(res => {
        console.log(res);
      })
    },
    uploadFile(event) {
      let files = event.target.files
      let file = files[0]
      let data = new FormData()
      data.append("image", file)


      console.log(data);
    }
  },
};
</script>

<style scoped lang="less">
@import url("../../assets/css/always.css");

.opinion_top {
  margin-bottom: 15px;
}
.opinion_title {
  font-size: 14px;
  font-weight: bold;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 35px;
    color: #fff;
    width: 135px;
    height: 135px;
    line-height: 135px;
    text-align: center;
    border: 1px dashed  #b9a9a9;
    background-color: #f2f3f7;
    border-radius: 5px;
  }
  .avatar {
    width: 135px;
    height: 135px;
    display: block;
  }

  .ava_text {
    font-size: 12px;
    color: rgb(241, 155, 42);
  }

  .upload-container {
    flex: 0 0 75px;
    width: 75px;
    height: 75px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff;
}

</style>