
/**
 * 校验银行卡号
 * @param {string} cardNo 银行卡号
 * @returns {boolean} true为合法，false为不合法
 */
function validateCardNo(cardNo) {
  // 银行卡号正则表达式
  var reg = /^([1-9]{1})(\d{15}|\d{18})$/;
  return reg.test(cardNo);
}

// 校验手机号
function validatePhone(phone) {
	var reg = /^1[3456789]\d{9}$/;
	return reg.test(phone);
}

function call(phone) {
	const res = uni.getSystemInfoSync();

	// ios系统默认有个模态框
	if (res.platform == 'ios') {
		uni.makePhoneCall({
			phoneNumber: phone,
			success() {
				console.log('拨打成功了');
			},
			fail() {
				console.log('拨打失败了');
			}
		})
	} else {
		//安卓手机手动设置一个showActionSheet
		uni.showActionSheet({
			itemList: [phone, '呼叫'],
			success: function(res) {
				if (res.tapIndex == 1) {
					uni.makePhoneCall({
						phoneNumber: phone,
					})
				}
			}
		})
	}
}

// 获取当前时间
function getCurentTime(
	symbolExpressYear = "-",
	symbolExpressMonth = "-",
	symbolExpress = ":"
) {
	let date = new Date();
	let yearTime = date.getFullYear(); // 获取完整的年份(4位,1970)
	let MonthTime = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1); //
	// 获取月份(0-11,0代表1月,用的时候记得加上1)
	let dayTime = date.getDate() > 9 ? date.getDate() : "0" + date.getDate(); // 获取日(01-31)

	let HoverTIme = date.getHours() > 9 ? (date.getHours()) : ("0" + date.getHours()); // 获取小时数(0-23)
	let MinyutesTime = date.getMinutes() > 9 ? (date.getMinutes()) : ("0" + date.getMinutes()); // 获取分钟数(0-59)
	let secondsTime = date.getSeconds() > 9 ? (date.getSeconds()) : ("0" + date.getSeconds()); // 获取秒(0-59)
	return (
		yearTime +
		symbolExpressYear +
		MonthTime +
		symbolExpressMonth +
		dayTime +
		" " +
		HoverTIme +
		symbolExpress +
		MinyutesTime +
		symbolExpress +
		secondsTime
	);
}

//  比较时间的大小
function timeTab(date1, date2) {
	// 因为苹果的时间格式是2020/10/19/ 00:00:00这样的格式；进行处理下
	let date_initial = date1.replace(/-/g, "/");
	let data_finsh = date2.replace(/-/g, "/");

	var oDate1 = new Date(date_initial);
	var oDate2 = new Date(data_finsh);
	if (oDate1.getTime() > oDate2.getTime()) {
		return 1;
	} else if (oDate1.getTime() < oDate2.getTime()) {
		return 2;
	} else if (oDate1.getTime() == oDate2.getTime()) {
		return 0;
	}
}

//时间戳转为时分秒
function dateFormat(key) {
	key = key * 1000
	//注意：key应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
	const dt = new Date(key);
	const y = dt.getFullYear();
	const m = (dt.getMonth() + 1 + '').padStart(2, '0');
	const d = (dt.getDate() + '').padStart(2, '0');
	const hh = (dt.getHours() + '').padStart(2, '0');
	const mm = (dt.getMinutes() + '').padStart(2, '0');
	const ss = (dt.getSeconds() + '').padStart(2, '0');
	//时间格式年月日、时分秒
	return `${hh}:${mm}:${ss}`;
}


/* 数组拆分 */
function splitArr(data) {
	var res = data.split(',')
	var newArr = []
	if (res.length > 0) {
		for (let i = 0; i < res.length; i++) {
			var dataArr = {
				value: i,
				status: false
			}
			newArr.push(dataArr)
		}
	}
	return newArr
}

function newNumber(start, end) {
	return Math.round(Math.random() * (end - start) + start); //生成在[start,end]范围内的随机数值，只支持不小于0的合法范围
}

function isHaveThisNumber(para, num) {
	//拒绝无效的数组
	if (typeof(para) == "object") {
		if (para.length == 0) {
			return false;
		}
	}
	for (var i = 0; i < para.length; i++) {
		if (para[i] == num) {
			return true; //与目标数组有重复
		}
	}
	return false;
}


//获取随机数
function random(min, max) {
	return parseInt(Math.random() * (max - min) + min);
	//Math.random()获取0-1中的随机数
	//随机数需要取整parseInt()，向下取整 Math.floor()
}

function newRandomNumbersWithNoRepeat(start, end, size, selectArr, replay) {
	var para = new Array(); //目标随机数组
	var rnum; //当前随机数
	var currentIndex = 0; //当前随机数组的索引
	if (start > end || start < 0 || end < 0 || size < 0) {
		return;
	}
	if (end - start + 1 < size) { //验证随机数个数是否超出随机数范围
		return;
	}
	for (var i = 0; i < size; i++) { //生成 size 个不重复的随机数
		if (selectArr && selectArr.length > 0 && i < selectArr.length) {
			para[currentIndex++] = selectArr[i]
		} else {
			rnum = formatNumber(newNumber(start, end), end, replay); //获取随机数
			if (isHaveThisNumber(para, rnum)) { //是否已经存在
				while (isHaveThisNumber(para, rnum)) { //获取新的随机数 直到不重复
					rnum = formatNumber(newNumber(start, end), end, replay); //重新获取随机数
				}
			}
			para[currentIndex++] = rnum; //添加到现有数字集合中
		}
	}
	func(para)
	return para;
}

function formatNumber(num, end, replay) {
	num = num.toString()
	let text = ''
	text = num[1] ? num : '0' + num
	if(end < 30) {
		if(replay) {
			text = num[1] ? num : '0' + num
		}else {
			text = num
		}
		
	}else {
		text = num[1] ? num : '0' + num
	}
	return text
}

/* 排序重置 */
function func(arr) {
	for (var i = 0; i < arr.length - 1; i++) {
		//每一轮比较要比多少次
		for (var j = 0; j < arr.length - 1 - i; j++) {
			//如果第一个比第二个大，就交换他们两个位置
			if (arr[j] > arr[j + 1]) {
				var temp = arr[j];
				arr[j] = arr[j + 1];
				arr[j + 1] = temp;
			}
		}
	}
}

function setCodeData(num) {
	let arr = []
	for (let i = 0; i < num; i++) {
		let data = {
			'value': formatNumber(i + 1),
			'status': 0
		}
		arr.push(data)
	}
	return arr
}



/**
 * 去掉字符串头尾空格
 */
function trim(str) {
	return str.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 身份证号验证
 */
function checkIdCard(idCard) {
	idCard = trim(idCard.replace(/ /g, "")); //去掉字符串头尾空格
	if (idCard.length == 15) {
		return isValidityBrithBy15IdCard(idCard); //进行15位身份证的验证
	} else if (idCard.length == 18) {
		var a_idCard = idCard.split(""); // 得到身份证数组
		if (isValidityBrithBy18IdCard(idCard) && isTrueValidateCodeBy18IdCard(a_idCard)) { //进行18位身份证的基本验证和第18位的验证
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}

/**
 * 判断身份证号码为18位时最后的验证位是否正确
 * @param a_idCard 身份证号码数组
 * @return
 */
function isTrueValidateCodeBy18IdCard(a_idCard) {
	var Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]; // 加权因子
	var ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2];
	var sum = 0; // 声明加权求和变量
	if (a_idCard[17].toLowerCase() == 'x') {
		a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
	}
	for (var i = 0; i < 17; i++) {
		sum += Wi[i] * a_idCard[i]; // 加权求和
	}
	var valCodePosition = sum % 11; // 得到验证码所位置
	if (a_idCard[17] == ValideCode[valCodePosition]) {
		return true;
	} else {
		return false;
	}
}

/**
 * 验证18位数身份证号码中的生日是否是有效生日
 * @param idCard 18位书身份证字符串
 * @return
 */
function isValidityBrithBy18IdCard(idCard18) {
	var year = idCard18.substring(6, 10);
	var month = idCard18.substring(10, 12);
	var day = idCard18.substring(12, 14);
	var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
	// 这里用getFullYear()获取年份，避免千年虫问题
	if (temp_date.getFullYear() != parseFloat(year) ||
		temp_date.getMonth() != parseFloat(month) - 1 ||
		temp_date.getDate() != parseFloat(day)) {
		return false;
	} else {
		return true;
	}
}

/**
 * 验证15位数身份证号码中的生日是否是有效生日
 * @param idCard15 15位书身份证字符串
 * @return
 */
function isValidityBrithBy15IdCard(idCard15) {
	var year = idCard15.substring(6, 8);
	var month = idCard15.substring(8, 10);
	var day = idCard15.substring(10, 12);
	var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
	// 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
	if (temp_date.getYear() != parseFloat(year) ||
		temp_date.getMonth() != parseFloat(month) - 1 ||
		temp_date.getDate() != parseFloat(day)) {
		return false;
	} else {
		return true;
	}
}


function calcDescartes(array) {
	if (array.length < 2) return array[0] || [];
	return array.reduce((total, currentValue) => {
		let res = [];
		total.forEach(t => {
			currentValue.forEach(cv => {
				if (t instanceof Array) {
					res.push([...t, cv]);
				} else {
					res.push([t, cv]);
				}
			})
		})
		return res;
	})
}

/* 加标识 */
function setMark(array) {
	let markList = [
		'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U',
		'V', 'W', 'X', 'Y', 'Z'
	]
	let newArr = []
	array.forEach((item, index) => {
		let codeList = []
		item.forEach(code => {
			let text = markList[index] + code
			codeList.push(text)
		})
		newArr.push(codeList)
	})
	return newArr
}

function deepfilterArr(array, min, isRemoval) {
	/* 标识后的数组 */
	let newArr = calcDescartes(array)
	if (array.length > min) {
		if (min == 1) {
			newArr = array
		} else {
			if (isRemoval) {
				let markList = setMark(array)
				let codeList = calcDescartes(markList)
				let filterArr = filterArray(codeList, min)
				newArr = removalArr(filterArr, isRemoval)
			} else {
				let codeList = calcDescartes(array)
				newArr = filterArray(codeList, min)
			}
		}
	}
	return newArr
}
/* 特殊数组去重 */
function removalArr(arr, isRemoval) {
	let newArr = []
	arr.forEach(item => {
		newArr.push(item.join(','))
	})
	let newList2 = []
	newArr.forEach((item) => {
		if (!newList2.includes(item)) {
			newList2.push(item)
		}
	})
	let newList3 = []
	newList2.forEach(item => {
		newList3.push(item.split(','))
	})
	let newList4 = []
	newList3.forEach(code => {
		let codeList = []
		code.forEach(item => {
			let text = item.slice(1)
			codeList.push(text)
		})
		newList4.push(codeList)
	})
	return newList4
}

function filterArray(arr, min) {
	let newAll = []
	arr.forEach(item => {
		/* 定义新数组 */
		let all = []
		for (var i = min; i < item.length; i++) {
			getFunArr(i, item, [], all);
		}

		let codeArr = all.filter(item => {
			return item.length == min
		})
		newAll.push(...codeArr)
	})
	return newAll
}

function getFunArr(n, src, got, all) {
	if (n == 0) {
		if (got.length > 0) {
			all[all.length] = got;
		}
		return;
	}
	for (var j = 0; j < src.length; j++) {
		getFunArr(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
	}
	return;
}

function setOdds(shou_zhu, shou_moneys) {
	// let shou_zhu = 5 // 总共赔率 
	// let shou_moneys = [27.51, 44.45, 50.38]
	// // let shou_moneys = [25.75,133.17]
	let len = shou_moneys.length
	let arr = []
	/* 2-1 */

	
	let moneyArr = []
	/* 4-1 */
	shou_moneys.forEach((item, index) => {
		let val = []
		for (var i = 0; i < len; i++) {
			if(i != index) {
				let bei = item/shou_moneys[i]
				val.push(bei)
			}
		}
		let count = 0
		val.forEach(valItem => {
			count = count + valItem
		})
		
		let base = shou_zhu / (count + 1)
		if(Math.round(base) <= 0)  {
			base  = 1
		}
		arr.push(Math.round(base))
	})
	
	
	let oddsCount = 0
	arr.forEach(item => {
		oddsCount = oddsCount + item
	})
	if(oddsCount != shou_zhu) {
		let codes = []
		shou_moneys.forEach((money, index) => {
			let val = money * arr[index]
			codes.push(Number(val.toFixed(3)))
		})
		const maxNum = Math.max(...codes)
		const minNum = Math.min(...codes)
		const [maxIndex, minIndex] = [codes.indexOf(maxNum), codes.indexOf(minNum)]
		let yu = 0
		arr.forEach((item, index) => {
			if(index != minIndex ) {
				yu = yu + item
			}
		})
		if(oddsCount > shou_zhu) {
			arr[minIndex] = shou_zhu - yu
		}else if(oddsCount < shou_zhu) {
			arr[minIndex] = arr[minIndex] + 1
		}
	}
	
	return arr
}

function splitArray(array,notPerice,odds, number, type, that) {
	let newArr = []
	array.forEach(arrangeItem => {
		let arr1 = []
		arrangeItem.forEach(codeItem => {
			let arr2 = []
			if(codeItem instanceof Array) {
				codeItem.forEach(baseItem => {
					arr2.push(baseItem.split(','))
				})
			}
			arr1.push(arr2)
		})
		newArr.push(arr1)
	})
	let maxList = filterSplitArray(newArr,notPerice,odds, number, type, that) 
	return maxList
}

function filterSplitArray(splitArr,notPerice,odds, number, type, that) {
	let codes = []
	let pinList = []
	/* 组合数组，这里还需要添加条件。。。。比分，球数，让胜等 */
	splitArr.forEach(splitItem => {
		splitItem.forEach(codeItem => {
			let titles = {
				signs:'',
				moneys: [],
				num: '',			// 让球 +1 -1
				score: '',			// 比分
				points: '',			// H D A  胜平负
				mark: ''
			}
			let flag = ''
			codeItem.forEach(base => {
				titles.mark = titles.mark + base[3]
				titles.moneys.push(base[1])
				let multiples = []
				if(base[0].length == 2) {
					pinList.push(codeItem)
					let text = titles.signs + base[0].slice(0,1)
					if(text == 'T') {	//	总进球数
						let num = base[0].substring(1,2)
						let numFlag = isOddEven(num)
						if(num == 0) {
							/* 进球数为0 只有平 */
							titles.signs = titles.signs + 'D'
						}else{  // 大于零的时候又分为奇数和偶数
							if(numFlag) {		// 奇数
								titles.signs = titles.signs + 'HA'
							}else {				// 偶数
								titles.signs = titles.signs + 'HDA'
							}
						}
					}else {
						titles.signs = titles.signs + base[0].substr(1)
					}
				}else if(base[0].length > 2) {		// 比分
					/* 获取比分 */
					if(type == 'soccer') {			// 足球
						titles.signs = titles.signs + base[0].slice(0,1)
						titles.score = base[0].substring(1,base[0].length);
					}else{
						titles.signs = titles.signs +  base[0].slice(0,1)
					}
				}else {
					if(type == 'soccer') {
						titles.signs = titles.signs + base[0].slice(0,1)
					}else {
						let text = base[0].slice(0,1)
						if(text == 'D' || text == "L") {
							titles.signs = titles.signs + 'HA'
						}else {
							titles.signs = titles.signs + base[0].slice(0,1)
						}
					}
				}
				if(base[2]) {
					titles.num = base[2]
					titles.points = base[0]
				}
			})
			if(!flag) {
				codes.push(titles)
			}
		})
	})
	// that.signsList.push(codes)
	let newArr = reduceArr(codes)
	let moneyList = []
	newArr.forEach(newItem => {
		let baseList = []
		codes.forEach(codItem => {
			// if(newItem == codItem.signs) {
			// 	baseList.push(codItem)
			// }
			if(compare(newItem,codItem.signs)) {
				baseList.push(codItem)
			}
		})
		moneyList.push(baseList)
	})
	let allList = []
	let allMoney = setMoneys(moneyList,notPerice,odds, number)
	
	return allMoney
}

/* 判断奇数偶数 */
function isOddEven(text) {
	let flag = false
	if(text %2 != 0) {
		flag = true		// 奇数
	}else {
		flag = false		// 偶数
	}
	return flag
}

function setMoneys(moneyList,notPerice,odds, number) {
	let mxaList = []
	moneyList.forEach(codeItem => {
		let mineyCode = 0
		codeItem.forEach(baseItem => {
			let card = 1
			baseItem.moneys.forEach(moneyItem => {
				card = card * moneyItem
			})
			let minPrice = card * notPerice * number * odds
			mineyCode = minPrice + mineyCode
			// mxaList.push(minPrice.toFixed(2))
		})
		mxaList.push(mineyCode.toFixed(3))
	})
	return mxaList
}

function reduceArr(codes) {
	let hash = []
	let	newArr = codes.reduce((pre, cur) => {
		hash[cur.signs] ? '' : hash[cur.signs] = true && pre.push(cur.signs)
		return pre
	}, [])
	return newArr
}
function compare(str1, str2) {
    var arr1 = [];
    var arr2 = [];
	let flag = false
    // 把两个字符串分别用循环转换为数组
    for(var i = 0; i < str1.length; i++){
        arr1.push(str1.charAt(i));
    }
    for(var i = 0; i < str2.length; i++){
        arr2.push(str2.charAt(i));
    }
	let len1 = getInclude(arr1, arr2)
	let len2 = getInclude(arr2, arr1)
	let text1 = bubbleSort(len1)
	let text2 = bubbleSort(len2)
   if(text1 == text2) {
       flag = true
   }else {
       flag = false
   }
   return flag
}

function bubbleSort(array) {
  const len = array.length
  if (len < 2) return array
  for (let i = 0; i < len; i++) {
    for (let j = 0; j < i; j++) {
      if (array[j] > array[i]) {
        const temp = array[j]
        array[j] = array[i]
        array[i] = temp
      }
    }
  }
  return array.join('')
}

/* 返回第二个数组的值 */
function  getInclude(arr1, arr2) {
  let temp = []
    for (const item of arr2) {
	  arr1.find(i => i === item) ? temp.push(item) : ''
	}
  return temp
}

/* 深度克隆对象 */
function newObj(obj) {
  var str, newobj = obj.constructor === Array ? [] : {};
  //constructor 属性返回对创建此对象的数组函数的引用。创建相同类型的空数据
  if (typeof obj !== 'object') {
    return;
  } else {
    for (var i in obj) {
      if (typeof obj[i] === 'object'){//判断对象的这条属性是否为对象
        newobj[i] = newObj(obj[i]);//若是对象进行嵌套调用
      }else{
        newobj[i] = obj[i];
      }
    }
  }
  return newobj;//返回深度克隆后的对象
}


/* 排列三复试算法 */
function arrangeThree(valLen,gutsLen) {
	let first = valLen - 2
	let second = valLen - 1
	let note = ''
	if(gutsLen > 0) {
		let code = valLen + gutsLen
		if(code <= 3) {
			note =　6
		}else {
			let con = code *  (code - 1 ) * (code -2)
			if(gutsLen == 1) {
				let stage = ''
				if(valLen >= 3) {
					stage = valLen * first * second
				}else {
					stage = 6
				}
				note = con - stage
			}else {
				let base = code - 1
				let base2 = code - 2
				let set = ''		// 设胆的
				let up = ''			// 没设但的
				if(valLen >= 3) {
					set = base * (base - 1) * (base - 2)
					let num =  base2 * (base2 - 1) * (base2 - 2)
					up = set - num
				}else {
					set = 6
					up = 6
				}
				note = con - set - up
			}
		}
	}else {
		note = valLen * first * second
	}
	return note
}

function setInclusive(len) {
	let note = 0
	switch (len) {
		case 2:
			note = 10
			break;
		case 3:
			note = 30
			break;
		case 4:
			note = 60
			break;
		case 5:
			note = 100
			break;
		case 6:
			note = 150
			break;
		case 7:
			note = 210
			break;
		case 8:
			note = 280
			break;
		case 9:
			note = 360
			break;
		case 10:
			note = 450
			break;
	}
	return note
}


/* 组选 组三 */
function arrangeGroup(valLen,gutsLen){
	let second = valLen - 1
	let note = ''
	if(gutsLen > 0) {
		note = valLen * 2
	}else{
		note = valLen * second
	}
	return note
}

function arrangeSix(valLen,gutsLen) {
	let note = ''
	if(gutsLen > 0) {
		let con = valLen + gutsLen
		if(gutsLen == 1) {
			let behind = setNote(valLen)
			let front = setNote(con)
			note = front - behind 
		}else {
			note = valLen
		}
	}else {
		note = setNote(valLen)
	}
	return note
}

function setNote(val) {
	let note = ''
	switch (val)
	{
		case 3:
			note = 1
			break;
		case 4:
			note = 4
			break;
		case 5:
			note = 10
			break;
		case 6:
			note = 20
			break;
		case 7:
			note = 35
			break;
		case 8:
			note = 56
			break;
		case 9:
			note = 84
			break;
		case 10:
			note = 120
			break;
	}
	return note
}

/**
 * 获得指定数组的所有组合
 */
function arrayCombine (targetArr = [], count = 1) {
  if (!Array.isArray(targetArr)) return []

  const resultArrs = []
  // 所有组合的 01 排列
  const flagArrs = getFlagArrs(targetArr.length, count)
  while (flagArrs.length) {
    const flagArr = flagArrs.shift()
    resultArrs.push(targetArr.filter((_, idx) => flagArr[idx]))
  }
  return resultArrs
}
/**
 * 获得从 m 中取 n 的所有组合
 * 思路如下：
 * 生成一个长度为 m 的数组，
 * 数组元素的值为 1 表示其下标代表的数被选中，为 0 则没选中。
 *
 * 1. 初始化数组，前 n 个元素置 1，表示第一个组合为前 n 个数；
 * 2. 从左到右扫描数组元素值的 “10” 组合，找到第一个 “10” 组合后将其变为 “01” 组合；
 * 3. 将其左边的所有 “1” 全部移动到数组的最左端
 * 4. 当 n 个 “1” 全部移动到最右端时（没有 “10” 组合了），得到了最后一个组合。
 */
function getFlagArrs (m, n = 1) {
  if (n < 1 || m < n)  return []

  // 先生成一个长度为 m 字符串，开头为 n 个 1， 例如“11100”
  let str = '1'.repeat(n) + '0'.repeat(m-n)
  let pos
  // 1
  const resultArrs = [Array.from(str, x => Number(x))]
  const keyStr = '10'

  while(str.indexOf(keyStr) > -1) {
    pos = str.indexOf(keyStr)
    // 2
    str = str.replace(keyStr, '01')
    // 3
    str = Array.from(str.slice(0, pos))
      .sort((a, b) => b-a)
      .join('') + str.slice(pos)
    // 4
    resultArrs.push(Array.from(str, x => Number(x)))
  }
  return resultArrs
}


/* 
	排列算法
	n代表有几个数
	选几个数就是m
 */
function countArrange(n, m) {
	/* 选择的数量必须大于排列数 */
	if(m > n) {
		return
	}
	let pour = 0		// 计算返回的注数
	let s = n - m
	if(s == 0) {
		pour = 1
	}else {
		let product = 1
		let discuss = 1
		
		for (var i = 0; i < s; i++) {
			let base = n - i
			product = product * base
		}
		
		for (var j = 1; j < s + 1; j++) {
			discuss = discuss * j
		}
		pour = product / discuss
	}
	console.log(pour);
	// return pour
	return Math.round(pour)		// 四舍五入
}

function getNowTime(type){
	const yy = new Date().getFullYear()
	const MM = (new Date().getMonth() + 1) < 10 ? '0' + (new             
		Date().getMonth() + 1) : (new Date().getMonth() + 1)
	const dd = new Date().getDate() < 10 ? '0' + new Date().getDate() : new 
		Date().getDate()
	const HH = new Date().getHours() < 10 ? '0' + new Date().getHours() : new 
		Date().getHours()
	const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : 
		 new Date().getMinutes()
	const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : 
		 new Date().getSeconds()
	let time
	if(type == 'base') {
		let year = yy.toString().slice(2)
		time = year + '/' + MM + '/' + dd + ' ' + HH + ':' + mm + ':' + ss;
	}else {
		time = yy + '-' + MM + '-' + dd+ ' ' + HH + ':' + mm + ':' + ss;
	}
	return time
}

/* 兄弟，看到赶紧跑路吧 */
module.exports = {
	call,
	splitArr,
	func,
	setCodeData,
	newRandomNumbersWithNoRepeat,
	checkIdCard,
	dateFormat,
	calcDescartes,
	filterArray,
	deepfilterArr,
	setOdds,
	splitArray,
	newObj,
	compare,
	arrayCombine,
	arrangeThree,
	arrangeGroup,
	arrangeSix,
	countArrange,
	timeTab,
	getCurentTime,
	validateCardNo,
	validatePhone,
	getNowTime,
	setInclusive
}
