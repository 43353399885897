/*
 * @Descripttion: 
 * @Date: 2023-11-12 21:54:20
 * @Author: lwh
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-27 13:10:04
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login/index.vue'
import home from '@/views/home/index.vue'
import football from '@/views/football/index.vue'
import basketball from '@/views/basketball/index.vue'
import beidan from '@/views/beidan/index.vue'
import arrange from '@/views/arrange/index.vue'
import arrFive from '@/views/arrange/arrFive.vue'
import goods from '@/views/goods/index.vue'
import periods from '@/views/periods/index.vue'
import setup from '@/views/setup/index.vue'


Vue.use(VueRouter)

// 解决重复点击路由报错的BUG
// 下面这段代码主要解决这个问题 ：Uncaught (in promise) Error: Redirected when going from "/login" to "/index" via a navigation guard.
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',    // 首页
    name: 'home',
    component: home,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/football',    //    足球
    name: 'football',
    component: football,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/beidan',    //    北单
    name: 'beidan',
    component: beidan,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/basketball',    //    篮球
    name: 'basketball',
    component: basketball,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/arrange',    //    排列三
    name: 'arrange',
    component: arrange,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/arrFive',    //    排列三
    name: 'arrFive',
    component: arrFive,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/periods',    //    往期
    name: 'periods',
    component: periods,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/goods',    //    我的订单
    name: 'goods',
    component: goods,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/setup',    //    我的订单
    name: 'setup',
    component: setup,
    meta: {
      keepAlive: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.APP_URL,
  routes
})

export default router

