<!--
 * @Descripttion: 公共的头部导航组件
 * @Date: 2023-11-12 21:54:20
 * @Author: lwh
 * @LastEditors: 靓仔辉
 * @LastEditTime: 2023-11-14 19:39:08
-->
<template>
  <div class="nav_box">
        <el-menu 
        default-active="1" 
        class="el-menu-demo" 
        mode="horizontal"
        active-text-color="#1868ca"
        @select="handleSelect"
        >
            <el-menu-item 
                :index="navItem.num" 
                v-for="(navItem, navIndex) in navList"
                :key="navIndex"
            >{{navItem.name}}</el-menu-item>
        </el-menu>
  </div>
</template>
<script>
export default {
    props: {
        navList: {
            type: Array,
            default: () => {
                return []
            }
        },
        activeIndex: {
            type: String,
            default: '1'
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            this.$emit('selectNav', key)
        }
    }
}
</script>

<style scoped lang="less">
    .nav_box {
        width: 100%;
       .el-menu.el-menu--horizontal {
            border-bottom: solid 2px #e6e6e6 !important;
        }
    }
</style>