<template>
  <div class="print-wrap">
    <!--startprint1-->
    <div style="width: 320px;
    padding: 0 15px;
    margin: 0 auto;
    color: #000;
    font-size: 12px;" id="printBom">
      <div style="font-size: 12px;">
        <div style="text-align: center;">
          <img style=" width: 120px;
    height: 35px;" src="./config/logo.png" />
        </div>
        <div style=" text-align: center;font-family: 'AWARDW';">竞彩足球混合过关</div>
        <div style="  display: flex;
        justify-content: space-between;
        font-family: 'AWARDW';">
          <div>140332700571816307865465</div>
          <div>32173217</div>
          <div>3X6361NA</div>
        </div>
      </div>
      <div style="padding: 5px 0;">
        <div style=" border-top: 1px dashed #000;"></div>
      </div>

      <div style="padding: 5px 0;
    height: 300px;
    overflow: hidden;">
        <div style="font-family: 'HEITI1';
    display: flex;
    justify-content: space-between;
    font-weight: 700;">
          <div>过关方式 2x1</div>
          <div>1倍</div>
          <div>合计 <span style=" font-family: 'HTITI';">2</span>元</div>
        </div>

        <!-- 比赛内容 -->
        <div style=" font-size: 12px;
          font-family: 'GGFS';">
          <div class="play_noe">
            <div style="display: flex;">
              <div  style="margin-right: 5px;">第一场</div>
              <div  style="margin-right: 5px;">周三301</div>
              <div class="note">预设总分:2285</div>
            </div>
            <div class="note_race">客队:华盛顿奇才 Vs 主队:底特律活塞</div>
            <div class="note_money">小<span class="test">@</span>1.700元</div>
          </div>
          <div class="play_noe">
            <div style="display: flex;">
              <div style="margin-right: 5px;">第一场</div>
              <div style="margin-right: 5px;">周三301</div>
              <div class="note">预设总分:2285</div>
            </div>
            <div class="note_race">客队:华盛顿奇才 Vs 主队:底特律活塞</div>
            <div class="note_money">
              小<span class="test">@</span
              ><span style=" font-family: 'HTITI';">1.700</span>元
            </div>
          </div>

          <div class="play_notes">(选项固定奖金额为每1元投注对应的奖金额)</div>
          <div class="play_regular">
            本票最高可能固定奖金:<span class="money_text">308.49</span>元
          </div>
          <div>单倍注数:2x1<span style=" font-family: 'HEITI';">*</span>1注;共1注</div>
        </div>

        <div style="font-family: 'HEITI';">
          <div
            style="display: flex;"
            v-for="(spotItem, soptIndex) in spotList"
            :key="soptIndex"
          >
            <div style="padding-left: 10px;">*</div>
            <div style="padding-left: 10px;">*</div>
            <div style="padding-left: 10px;">*</div>
          </div>
        </div>
      </div>

      <div style=" padding: 5px 0;">
        <div style=" border-top: 1px dashed #000;"></div>
      </div>

      <div style="text-align: center;
        font-weight: 'ICOMOON';"      >
        <div>感谢您为公益事业贡献 8.82元</div>
        <div style="margin-top: 10px;">北京路265号</div>

        <div style=" display: flex;
    justify-content: space-between;">
          <div class="left">02-019718-101</div>
          <div class="right">23/11/29 15:08:53</div>
        </div>
        <div style=" width: 100%;">
          <img style=" width: 100%;
    height: 60px;" src="./config/bderweima.png" />
        </div>
      </div>
    </div>
    <!--endprint1-->
  </div>
</template>

<script>
export default {
  props: {
    printHtml: "",
  },
  data() {
    return {
      spotList: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
    };
  },
};
</script>