/*
 * @Descripttion: 
 * @Date: 2023-11-14 15:19:01
 * @Author: lwh
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-27 15:24:06
 */

import { get, post } from './request'

export const getRealtumefootball = p => get('/realtimefootball', p);

export const getHomeForm = p => get('/index', p);

export const onLogin = p => post('/login', p);

export const getRealtumeBasketball = p => get('/realtimebaskball', p);

export const getRealtimebeidan = p => get('/realtimebeidan', p);

export const getCodingGain = p => get('/CodingGain', p);    //票面编码获取

export const getThreeissue = p => get('/threeissue', p);     // 排列三期数

export const getFiveissue = p => get('/fiveissue', p);     // 排列五期数

export const getOrderSearch = p => post('/orderData', p);     // 往期数据

export const getOrderDetails = p => get('/orderDetails', p);     // 往期数据

export const postArrangeThree = p => post('/arrange_three', p);     // 排列三下单

export const postArrangeFive = p => post('/arrange_five', p);     // 排列五下单

export const updateName = p => post('/revamp_name', p);     // 修改用户名称

export const updatePassword = p => post('/newPassword', p);     // 修改密码

export const getFeedbackList = p => post('/feedbackList', p);     // 查询反馈记录

export const addSubmitFeedback = p => post('/submitFeedback', p);     // 添加反馈记录

export const updateChromaticaCoding = p => post('/ChromaticaCoding', p);     // 竞彩票面设置

export const getChromaticaCoding = p => post('/faceSelectNumber', p);     // 获取票面设置信息

export const getPreviousdata = p => get('/previousdata', p);     // 获取票面设置信息

export const getPreviousJz = p => get('/previousJz', p);     // 获取往期足球数据

export const getPreviousJl = p => get('/previousJl', p);     // 获取往期篮球数据

export const getPreviousBd = p => get('/previousBjdc', p);     // 获取往期北单数据

export const updateImg = p => post('/up_file', p);     // 上传文件

export const contestLotterys = p => post('/Contest_lotterys', p);     // 上传文件

export const getBjdcIssue = p => get('/bjdcIssue', p);     // 获取被单期数

export const setBonus_update = p => get('/Bonus_update', p);     // 修改中将金额

export const postPrize = p => get('/Prize', p);     // 原票兑奖

export const postPrintOrder = p => get('/print_order', p);     // 打印

export const postManuscript = p => get('/manuscript', p);     // 原票打印

