<!--
 * @Descripttion: 排列五
 * @Date: 2023-12-27 13:03:31
 * @Author: SYC
 * @LastEditors: HUI
 * @LastEditTime: 2024-06-23 21:01:05
-->
<template>
  <div :class="outerStyle ? 'container_component' : ''" v-loading="loading">
    <div class="always_box">
      <div >
        <div class="df_c_s p_t_10" v-if="outerStyle == true">
          <div class="period_box">当前期数：{{ nextPeriod }}</div>
          <div class="period_time">{{ expireTime }}</div>
        </div>
        
        <div class="df_c_s p_t_10" v-else>
            <div class=" df_c_s">
                <div>
                    当前期数:
                </div>
                <div class="select_code">
                    <el-select width="120" 
                    @change="periodChange"
                    v-model="nextPeriod" 
                    placeholder="请选择">
                        <el-option
                        v-for="item in history"
                        :key="item.issueno"
                        :label="item.issueno"
                        :value="item.issueno">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="period_time">{{ expireTime }} </div>
        </div>

        <div class="arrange_box">
          <div class="arrange_content">
            <div class="df_c">
              <div class="left">
                <div class="top_list">
                    <el-radio-group 
                        v-model="fixeType"
                        @input="selectPosition"
                    >
                        <el-radio-button :label="straightItem.code" 
                        v-for="(straightItem, straightIndex) in straightTopList"
                        :key="straightIndex"
                        >
                            {{ straightItem.label }}
                        </el-radio-button>
                    </el-radio-group>
                </div>
                <div class="arrange_code mt_20">
                  <div>
                    <div class="arrange_sign df_c">
                      <div class="description">万位</div>
                      <div class="con_ul df_s">
                        <div
                          class="con_li cursor"
                          v-for="(manysItem, manysIndex) in manys"
                          @click="selectBall(manysItem, 'many', manys)"
                          :key="manysIndex"
                          :class="manysItem.status == 1 ? 'ball_select' : ''"
                        >
                          {{ manysItem.val }}
                        </div>
                      </div>
                    </div>
                    <div class="arrange_sign df_c">
                      <div class="description">千位</div>
                      <div class="con_ul df_s">
                        <div
                          class="con_li cursor"
                          v-for="(kilosItem, kilosIndex) in kilos"
                          @click="selectBall(kilosItem, 'kilo', kilos)"
                          :key="kilosIndex"
                          :class="kilosItem.status == 1 ? 'ball_select' : ''"
                        >
                          {{ kilosItem.val }}
                        </div>
                      </div>
                    </div>
                    <div class="arrange_sign df_c">
                      <div class="description">百位</div>
                      <div class="con_ul df_s">
                        <div
                          class="con_li cursor"
                          v-for="(hundredItem, hundredIndex) in hundreds"
                          @click="selectBall(hundredItem, 'hundred', hundreds)"
                          :key="hundredIndex"
                          :class="hundredItem.status == 1 ? 'ball_select' : ''"
                        >
                          {{ hundredItem.val }}
                        </div>
                      </div>
                    </div>
                    
                    <div class="arrange_sign df_c">
                      <div class="description">十位</div>
                      <div class="con_ul df_s">
                        <div class="con_ul df_s">
                          <div
                            class="con_li cursor"
                            v-for="(tenItem, tenIndex) in tens"
                            @click="selectBall(tenItem, 'then', tens)"
                            :key="tenIndex"
                            :class="tenItem.status == 1 ? 'ball_select' : ''"
                          >
                            {{ tenItem.val }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="arrange_sign df_c">
                      <div class="description">个位</div>
                      <div class="con_ul df_s">
                        <div
                          class="con_li cursor"
                          v-for="(unitItem, unitIndex) in units"
                          @click="selectBall(unitItem, 'unit', units)"
                          :key="unitIndex"
                          :class="unitItem.status == 1 ? 'ball_select' : ''"
                        >
                          {{ unitItem.val }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right df_c" v-if="fixeType == 1">
                <div class="add_btn">
                    <el-button 
                      :disabled="addButtonShow"
                      type="primary"
                      @click="addSelection('add')"
                      round>添加</el-button>
                  </div>
                  <div class="m_l_15 operate_table" >
                      <div class="table_top df">
                          <div class="table_item table_left">已选号码</div>
                          <div class="table_item table_right">操作</div>
                      </div>
                      <div class="table_con" v-if="selectionList.length > 0">
                          <div class="con_item df" 
                              v-for="(selectItem, selectIndex) in selectionList"
                              :key="selectIndex"
                              >
                              <div class="table_item table_left df_c">
                                  <div>
                                    <div class="select_num hundred m_r_5"
                                    v-for="(manyItem, manyIndex) in selectItem.many"
                                    :key="manyIndex"
                                    >
                                        {{ manyItem }}
                                    </div>
                                  </div>
                                  <div class="m_r_5">|</div>
                                  <div>
                                    <div class="select_num hundred m_r_5"
                                    v-for="(kiloItem, kiloIndex) in selectItem.kilo"
                                    :key="kiloIndex"
                                    >
                                        {{ kiloItem }}
                                    </div>
                                  </div>
                                  <div class="m_r_5">|</div>
                                  <div>
                                    <div class="select_num hundred m_r_5"
                                    v-for="(hundredItem, hundredIndex) in selectItem.hundred"
                                    :key="hundredIndex"
                                    >
                                        {{ hundredItem }}
                                    </div>
                                  </div>
                                  <div class="m_r_5">|</div>
                                  <div>
                                    <div class="select_num then m_r_5"
                                    v-for="(thenItem, thenIndex) in selectItem.then"
                                    :key="thenIndex"
                                    >
                                        {{ thenItem }}
                                    </div>
                                  </div>
                                  <div class="m_r_5">|</div>
                                  <div>
                                    <div class="select_num unit m_r_5" 
                                    v-for="(unitItem, unitIndex) in selectItem.unit"
                                    :key="unitIndex"
                                    >
                                      {{ unitItem }}
                                    </div>
                                  </div>
                              </div>
                              <div @click="delStatusNum(selectIndex)" class="table_item table_right red cursor">删除</div>
                          </div>
                      </div>
                      <div class="operate_null" v-else>
                          暂无数据
                      </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="arrange_descri">
            <span
              >共<span class="red">{{ pour }}</span
              >注，共￥<span class="red">{{ amount }}</span
              >元</span
            >
          </div>
          <div class="arrange_bottom">
            <div class="multiple">
                <div class="m_r_10">投注倍数:</div>
                <div class="m_r_10">
                    <el-input-number
                        size="mini"
                        v-model="bettingNum"
                        @change="valChange"
                        :min="1"
                        :disabled="customerDisabled"
                    >
                    </el-input-number>
                </div>
            </div>
            <div class="multiple">
                <div class="m_r_10">客户名称:</div>
                <div class="right_input m_r_10">
                    <el-input
                        size="mini"
                        width="80px"
                        class="m_r_10"
                        v-model="customerName"
                        placeholder="请输入客户名称"
                    ></el-input>
                </div>
            </div>
            <div class="multiple">
                <div class="m_r_10">打印份数:</div>
                <div class="m_r_10">
                    <el-input-number
                        size="mini"
                        v-model="printNum"
                        :min="1"
                        label="描述文字"
                    >
                    </el-input-number>
                </div>
            </div>
            <div class="multiple">
                <div class="m_r_10">打印时间:</div>
                <div class="m_r_10">
                    <el-date-picker
                        v-model="currentVal"
                        type="datetime"
                        placeholder="选择日期时间"
                        >
                    </el-date-picker>
                </div>
            </div>
            <el-button size="mini"
                @click="onSubmit"
                :disabled="pour == 0"
                type="danger">提交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import multipleData from "@/components/package/multipleData.js";
import { getFiveissue, getCodingGain, postArrangeFive } from "@/utils/api.js";   // 接口api
import {
		newObj,
    getNowTime
	} from '@/components/package/unit.js'

export default {
  props: {
      outerStyle:  {   // false 往期数据
      type: Boolean,
          default: true
      }
  },
  data() {
    return {
      nextPeriod: "",
      history: [],
      expireTime: "",
      pour: 0,
      amount: 0,
      straightTopList: [
        {
            label: '标准选号',
            code: 1,
        },
        {
          label: '复式选号',
          code: 2,
        },
      ],
      fixeType: 1,
      fiveForm: {
        name: "标准选号",
        many: [], // 万位
        kilo: [], // 千位
        hundred: [], // 百位
        then: [], // 十位
        unit: [], // 个位
        type: "f-z",
        odds: "", //	注数
        money: 0, //  金额
      },
      manys: [], // 万位
      kilos: [], // 千位
      hundreds: [], // 百位
      tens: [], // 十位
      units: [], // 个位
      pourMoney: 2,       // 单注固定2yuan
      bettingNum: 1,  //投注倍数
      customerDisabled: false,
      customerName: "中国体育彩票", //  客户名称
      printNum: 1,     // 打印份数
      currentVal: '',
      selectionList: [],   // 选中的集合，
      addButtonShow: true,
      loading: true,
      printForm: {}
    };
  },
  mounted() {
    this.customerName = localStorage.getItem('userName')
    this.manys = JSON.parse(JSON.stringify(multipleData.ments));
    this.kilos = JSON.parse(JSON.stringify(multipleData.ments));
    this.hundreds = JSON.parse(JSON.stringify(multipleData.ments));
    this.tens = JSON.parse(JSON.stringify(multipleData.ments));
    this.units = JSON.parse(JSON.stringify(multipleData.ments));
    this.getArrangeNum()
  },
  methods: {
    selectPosition(e) {
      if(e == 1) {
        this.fiveForm.name = '标准选号'
      }else {
        this.fiveForm.name = '复式选号'
      }
      this.delStatus()
      this.bettingNum = 1
      this.selectionList = []
    },
    getArrangeNum() {
      this.loading = true
      getFiveissue({}).then(res => {
        this.loading = false
        this.history = res.data.history
        if(this.outerStyle) {
          this.nextPeriod = res.data.next_period
          this.expireTime = res.data.expire_time
        }else {
          this.nextPeriod = this.history[0].issueno
          this.expireTime =  this.history[0].expire_time
          this.currentVal = this.dateFormat(this.history[0].expire_time)
        }
       
      })
      getCodingGain({
          type: 3,
          print_versions: 1
      }).then(res => {
          if(res.code == 200) {
              this.printForm = res.data
          }
      })
    },
    addSelection(type) {
      this.selectionList.push(newObj(this.fiveForm))
      this.delStatus()
      this.addButtonShow = true
      if(type == 'add') {
          this.getArrangeNote()
      }
    },
    delStatusNum(index) {
      this.selectionList.splice(index,1)
      this.getArrangeNote()
    },
    getArrangeNote() {
        let note = 0
       
        if(this.fixeType == 1) {
          this.selectionList.forEach(item => {
            note = note + Number(item.odds) 
          })
          this.pour = Math.trunc(note)
        }else {
          this.pour = Math.trunc(this.fiveForm.odds)
        }
        this.amount = this.pour * this.bettingNum * this.pourMoney
    },
    async onSubmit() {
        let proceeds = (this.amount * 0.34).toFixed(3)
        if(this.fixeType == 2) {
          this.selectionList.push(this.fiveForm)
        }
        let data = {
            navType: this.fixeType,
            issue: this.nextPeriod,           // 期数
            random_number: this.printForm.random_number,
            random_numberT: this.printForm.random_numberT,
            random_numberS: this.printForm.random_numberS,
            random_numberF: this.printForm.random_numberF,
            random_numbeV: this.printForm.random_numbeV,
            randomString: this.printForm.randomString,
            multiple: this.bettingNum,                        // 倍数
            bet_money: this.amount,              // 合计金额
            lottery: JSON.stringify(this.selectionList),                            // 选择的参数数组
            lotteryProceeds: proceeds,                    // 公益贡献金额
            random_numbeE: this.printForm.random_numbeE,
            currentDate: this.currentVal =='' ? getNowTime('base') : this.getCurrentTime(this.currentVal),                                  // 打印时间
            lottery_add: this.printForm.lottery_add,
            expire_time: this.expireTime,                        // 开奖时间
            order_time: getNowTime(),
            print_time: getNowTime(),
            customer_name: this.customerName,
            note: this.pour
        }
        this.loading = true
        postArrangeFive(data).then(res => {
          this.loading = false
          if(res.code == 200) {
              this.$message({
                  type: "success",
                  message: res.msg,
              });
              this.delStatus(this.navIndex)
              this.bettingNum = 1
              this.selectionList = []
          }

        })

        getCodingGain({
          type: 3,
          print_versions: 1
        }).then(res => {
            if(res.code == 200) {
                this.printForm = res.data
            }
        })
    },

    getCurrentTime(key) {
      const dt =  new Date(key);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + '').padStart(2, '0');
      const d = (dt.getDate() + '').padStart(2, '0');
      const hh = (dt.getHours() + '').padStart(2, '0');
      const mm = (dt.getMinutes() + '').padStart(2, '0');
      const ss = (dt.getSeconds() + '').padStart(2, '0');
      //时间格式年月日、时分秒
      //时间格式年月日、时分秒
      return `${y}/${m}/${d} ${hh}:${mm}:${ss}`;
    },
    dateFormat(key) {
        let replacedStr = key.replace('年', '-').replace('月', '-').replace('日', ' ');
        const dt =  new Date(replacedStr);
        const y = dt.getFullYear();
        const m = (dt.getMonth() + 1 + '').padStart(2, '0');
        const d = (dt.getDate() + '').padStart(2, '0');
        //时间格式年月日、时分秒
        return `${y}/${m}/${d} 00:00:00`;
    },
    // 投注倍数
    valChange(e) {
      this.getArrangeNote()
    },
    async selectBall(item, code, baseList) {
      await this.setValue(item, code, baseList);
      if (!this.isCondition()) {
        this.meetcount();
      } else {
        if(this.fixeType == 2) {
          this.pour = 0
          this.amount = 0
        }else {
          this.addButtonShow = true
        }
      }
    },
    setValue(item, code, baseList) {
      if (item.status == 0) {
        if(this.fixeType == 1) {
          baseList.forEach((item) => {
            item.status = 0;
          });
          this.fiveForm[code] = []
        }
        item.status = 1;
        this.fiveForm[code].push(item.val);
      } else {
        item.status = 0;
        this.deleteArrIndex(this.fiveForm[code], item.val);
      }
    },
    /* 查询index */
    deleteArrIndex(array, val) {
      let text = "";
      array.forEach((item, index) => {
        if (item == val) {
          text = index;
        }
      });
      array.splice(text, 1);
    },
    isCondition() {
      let falg = true;
      let manyLen = this.fiveForm.many.length;
      let kiloLen = this.fiveForm.kilo.length;
      let thenLen = this.fiveForm.then.length;
      let hundredLen = this.fiveForm.hundred.length;
      let unitLen = this.fiveForm.unit.length;

      if (
        manyLen > 0 &&
        kiloLen > 0 &&
        thenLen > 0 &&
        hundredLen > 0 &&
        unitLen > 0
      ) {
        falg = false;
      }
      return falg;
    },
    meetcount() {
      this.addButtonShow = false
      let manyLen = this.fiveForm.many.length;
      let kiloLen = this.fiveForm.kilo.length;
      let thenLen = this.fiveForm.then.length;
      let hundredLen = this.fiveForm.hundred.length;
      let unitLen = this.fiveForm.unit.length;

      this.fiveForm.odds = hundredLen * thenLen * unitLen * kiloLen * manyLen;
      this.fiveForm.money = this.fiveForm.odds * this.pourMoney;

      if(this.fixeType == 2) {
        this.pour = Math.trunc(this.fiveForm.odds)
        this.amount = this.pour * this.bettingNum * this.pourMoney
      }
    },
    /* 清空所有状态 */
    delStatus() {
      this.pour = 0;
      this.amount = 0;
      this.fiveForm.hundred = [];
      this.fiveForm.then = [];
      this.fiveForm.unit = [];
      this.fiveForm.many = [];
      this.fiveForm.kilo = [];
      this.fiveForm.odds = "";
      this.fiveForm.money = 0;
      this.hundreds.forEach((item) => {
        item.status = 0;
      });
      this.tens.forEach((item) => {
        item.status = 0;
      });
      this.units.forEach((item) => {
        item.status = 0;
      });
      this.manys.forEach((item) => {
        item.status = 0;
      });
      this.kilos.forEach((item) => {
        item.status = 0;
      });
    },
    periodChange(e) {
      let time = this.getExpireTime(e)
      this.currentVal =  this.dateFormat(time)
    },
    getExpireTime(issue) {
      let val = ''
      this.history.forEach(hisItem => {
          if(hisItem.issueno == issue) {
              this.expireTime = hisItem.expire_time
              val = hisItem.expire_time
          }
      })
      return val
    },
  },
};
</script>


<style scoped lang="less">
@import url("../../assets/css/always.css");
.single_f {
  background: #f53629 !important;
  color: #fff !important;
}
.arrange_box {
  padding: 20px 35px;
  .arrange_content {
    padding-bottom: 15px;
    border-bottom: 1px solid #e6e6e6;
    .left {
    }
    .right {
      margin-left: 55px;
    }
    .arrange_code {
      .arrange_sign {
        font-size: 14px;
        margin-bottom: 15px;
        .description {
          text-align: center;
          width: 90px;
          color: #8d8585;
        }
        .con_ul {
          .con_li {
            margin-left: 8px;
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            color: #000;
            font-weight: bold;
            border-radius: 50%;
            border: 1px solid #e6e6e6;
          }
          .ball_select {
            background-color: #409eff;
            color: #fff;
          }
        }
      }
    }
  }
  .descri_text {
    color: #8d7f7f;
  }
  // 选中的描述
  .arrange_descri {
    color: #8d7f7f;
    padding: 15px 0;
    font-size: 15px;
  }
  // 底部
  .arrange_bottom {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 15px 0;
  }
}

.group_width {
  flex-wrap: wrap;
  width: 640px;
  .con_li {
    margin-bottom: 10px;
  }
}

.group_and_width {
  flex-wrap: wrap;
  width: 580px;
  .con_li {
    margin-bottom: 10px;
  }
}

.table_top {
  font-weight: bold;
  border-bottom: 1px solid #e6e6e6;
}
.table_item {
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
}

.table_flex {
  flex: 1;
}

.border_right {
  border-right: 1px solid #e6e6e6;
}

.table_con {
  height: 165px;
  overflow-y: auto;
  color: #978686;
  .con_item {
    border-bottom: 1px solid #e6e6e6;
  }
}

.select_num {
  font-size: 15px;
  color: #000;
  font-weight: 500;
}
.operate_table {
  width: 450px;
  height: 200px;
  border: 1px solid #e6e6e6;
  color: #000;
  overflow: hidden;
  .table_left {
    width: 350px;
    border-right: 1px solid #e6e6e6;
  }
  .table_right {
    flex: 1;
  }
}

.min_table {
  width: 500px;
  height: 200px;
  border: 1px solid #e6e6e6;
  color: #000;
  overflow: hidden;
}

.operate_null {
  text-align: center;
  color: #8d8282;
  line-height: 150px;
}

.red {
  color: #f53629;
}

.select_code {
  width: 120px;
  margin-left: 20px;
}
</style>