<!--
 * @Descripttion: 
 * @Date: 2023-11-12 21:54:20
 * @Author: lwh
 * @LastEditors: 靓仔辉
 * @LastEditTime: 2023-11-21 15:19:31
-->
<template>
    <div class="container_component">
      <div class="home">
          <div class="home_top">
              <div class="home_title">订单统计</div>
          </div>
          <div class="home_content">
            <div class="con_statistics df_s">
              <div class="statistics_box blue">
                <div class="statistics_text pl_15">昨天订单</div>
                <div class="statistics_text pl_15 ">{{ homeForm.yesterday_order }}</div>
              </div>
              <div class="statistics_box pink">
                <div class="statistics_text pl_15">昨天销售额</div>
                <div class="statistics_text pl_15 "> {{ homeForm.yesterday_salesVolume }} </div>
              </div>
              <div class="statistics_box red">
                <div class="statistics_text pl_15">昨天中奖金额</div>
                <div class="statistics_text pl_15 ">{{ homeForm.yesterday_bonus }}</div>
              </div>
              <div class="statistics_box green">
                <div class="statistics_text pl_15">今日单数</div>
                <div class="statistics_text pl_15 ">{{ homeForm.day_order }}</div>
              </div>
              <div class="statistics_box yellow">
                <div class="statistics_text pl_15">今日销售额</div>
                <div class="statistics_text pl_15 ">{{ homeForm.sales_volume }}</div>
              </div>
            </div>
            <div class="con_specific df">
              <div class="specific_box1 m_r_15">
                  <div class="specific_title df_s">
                      <div class="pl_15">到期时间</div>
                  </div>
                    <div class="specific_time_con">
                      <div class="specific_item df_c_s">
                        <div class="left">
                          <span class="color_red">{{ homeForm.expiration_time }}</span>
                        </div>
                        <div class="color_red">距到期还剩
                         <span class="day">{{ homeForm.expiration_days }}</span>
                          天</div>
                      </div>
                  </div>
              </div>
               <div class="specific_box2 ">
                  <div class="specific_title df_s">
                      <div class="pl_15">联系我们</div>
                  </div>
                  <div class="specific_my_con">
                    欢迎
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </template>
  <script>
  import {getHomeForm} from "@/utils/api.js";
  export default {
      mane: 'login',
      data() {
          return {
              homeForm: {}
          }
      },
      mounted() {
        this.getHomeData()
      },
      methods: {
        getHomeData() {
          getHomeForm({
            username: 'admin'
          }).then(res => {
            if(res.code == 200) {
              this.homeForm = res.data
            }
          })
        }
      }
  }
  </script>
  
  <style scoped lang="less">
    .home {
        .home_top {
          border-bottom: 1px solid #f8e9e9;
        }
        .home_title {
          font-weight: 600;
          padding: 10px 15px;
          font-size: 15px;
        }

        .home_content {
          padding: 15px;
          .con_statistics {
            width: 100%;
            font-size: 14px;
            color: #fff;
            .statistics_box {
              border-radius: 8px;
              width: 19%;
              font-weight: bold;
              .statistics_text {
                height: 45px;
                line-height: 45px;
              }
            }
          } 
        }
        .con_specific {
          margin-top: 15px;
           .specific_title {
              border-bottom: 1px solid #f6f5f7;
               height: 45px;
              line-height: 45px;
            }
             .specific_my_con {
              padding: 10px 15px;
            }
          .specific_box1 {
            width: 35%;
            border: 1px solid #f6f5f7;
            border-radius: 5px;
            .day {
              color: #4f4fec;
              font-size: 18px;
              font-weight: bold;
            }
            .specific_time_con {
              padding-bottom: 15px;
            }
            .specific_item  {
              margin-top: 10px;
              padding: 0 15px;
            }
          }

          .specific_box2{
              width: 35%;
              border: 1px solid #f6f5f7;
              border-radius: 5px;
          }
        }
    }

    .blue {
      background-color: #4788f4;
    }
    .pink{
      background-color: #9174f6;
    }
    .red {
      background-color: #f87179;
    }
    .green{
      background-color: #17cce3;
    }
    .yellow{
      background-color: #fccd74;
    }

    .color_red {
      color: #e72e5d;
    }

  </style>