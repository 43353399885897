/*
 * @Descripttion: 
 * @Date: 2023-12-04 16:42:07
 * @Author: SYC
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-06 12:38:57
 */
// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default{
  install (Vue, options) {
    Vue.prototype.getPdf = function () {
      var title = '竞彩打印c'
      html2Canvas(document.querySelector('#printBom'), {
        allowTaint: true
      }).then(function (canvas) {
        console.log(canvas);
        let contentWidth = canvas.width
        let contentHeight = canvas.height
        let pageHeight = contentWidth / 72 * 200
        let leftHeight = contentHeight
        //页面偏移
        let position = 0
        let imgWidth = 350
        let imgHeight = canvas.height
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let PDF = new JsPDF('p', 'mm', [72, 200])
        var a4w = 72; var a4h = 300 // A3大小，279mm x 420mm，四边各保留10mm的边距，显示区域259x400
        PDF.addImage(pageData, 'JPEG', 0, 0, 72, 100)
        // if (leftHeight < pageHeight) {
        //   console.log(1111);
        // } else {
        //   console.log(22222);
        //   while (leftHeight > 0) {
        //     PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        //     leftHeight -= pageHeight
        //     position -= 841.89
        //     if (leftHeight > 0) {
        //       PDF.addPage()
        //     }
        //   }
        // }
        console.log(PDF);
        PDF.save(title + '.pdf')
      }
      )
    }
  }
}