const multipleList = [
	{
		len: 3,
		list: [
			{
				name: '3串3',
				key: 3,
				status: 0,
				keys: [1],
				selectVal: '3x3'			
			},
			{
				name: '3串4',
				key: 4,
				status: 0,
				selectVal: '3x4',		
				keys: [1,2]
			}
		]
	},
	{
		len: 4,
		list: [
			{
				name: '4串4',
				key: 4,
				status: 0,
				selectVal: '4x4',		
				keys: [2]
			},
			{
				name: '4串5',
				key: 5,
				status: 0,
				selectVal: '4x5',		
				keys: [2,3]
			},
			{
				name: '4串6',
				key: 6,
				status: 0,
				selectVal: '4x6',
				keys: [1]
			},
			{
				name: '4串11',
				key: 11,
				status: 0,
				selectVal: '4x11',
				keys: [1,2,3]
			}
		]
	},
	{
		len: 5,
		list: [
			{
				name: '5串5',
				key: 5,
				status: 0,
				selectVal: '5x5',
				keys: [3]
			},
			{
				name: '5串6',
				key: 6,
				status: 0,
				selectVal: '5x6',
				keys: [3,4]
			},
			{
				name: '5串10',
				key: 10,
				status: 0,
				selectVal: '5x10',
				keys: [1]
			},
			{
				name: '5串16',
				key: 16,
				status: 0,
				selectVal: '5x16',
				keys: [2,3,4]
			},
			{
				name: '5串20',
				key: 20,
				status: 0,
				selectVal: '5x20',
				keys: [1,2]
			},
			{
				name: '5串26',
				key: 26,
				status: 0,
				selectVal: '5x26',
				keys: [1,2,3,4]
			},
		]
	},
	{
		len: 6,
		list: [
			{
				name: '6串6',
				key: 6,
				status: 0,
				selectVal: '6x6',
				keys: [4]
			},
			{
				name: '6串7',
				key: 7,
				status: 0,
				selectVal: '6x7',
				keys: [4,5]
			},
			{
				name: '6串15',
				key: 15,
				status: 0,
				selectVal: '6x15',
				keys: [1]
			},
			{
				name: '6串20',
				key: 20,
				status: 0,
				selectVal: '6x20',
				keys: [2]
			},
			{
				name: '6串22',
				key: 22,
				status: 0,
				selectVal: '6x22',
				keys: [3,4,5]
			},
			{
				name: '6串35',
				key: 35,
				status: 0,
				selectVal: '6x35',
				keys: [1,2]
			},
			{
				name: '6串42',
				key: 42,
				status: 0,
				selectVal: '6x42',
				keys: [2,3,4,5]
			},
			{
				name: '6串50',
				key: 50,
				status: 0,
				selectVal: '6x50',
				keys: [1,2,3]
			},
			{
				name: '6串57',
				key: 57,
				status: 0,
				selectVal: '6x57',
				keys: [1,2,3,4,5]
			}
		]
	},
	{
		len: 7,
		list: [
			{
				name: '7串7',
				key: 7,
				status: 0,
				selectVal: '7x7',
				keys: [5]
			},
			{
				name: '7串8',
				key: 8,
				status: 0,
				selectVal: '7x8',
				keys: [5,6]
			},
			{
				name: '7串21',
				key: 21,
				status: 0,
				selectVal: '7x21',
				keys: [4]
			},
			{
				name: '7串35',
				key: 35,
				status: 0,
				selectVal: '7x35',
				keys: [3]
			},
			{
				name: '7串120',
				key: 120,
				status: 0,
				selectVal: '7x120',
				keys: [1,2,3,4,5,6]
			}
		]
	},
	{
		len: 8,
		list: [
			{
				name: '8串8',
				key: 8,
				status: 0,
				selectVal: '8x8',
				keys: [6]
			},
			{
				name: '8串9',
				key: 9,
				status: 0,
				selectVal: '8x9',
				keys: [6,7]
			},
			{
				name: '8串28',
				key: 28,
				status: 0,
				selectVal: '8x28',
				keys: [5]
			},
			{
				name: '8串56',
				key: 56,
				status: 0,
				selectVal: '8x56',
				keys: [4]
			},
			{
				name: '8串70',
				key: 70,
				status: 0,
				selectVal: '8x70',
				keys: [3]
			},
			{
				name: '8串247',
				key: 247,
				status: 0,
				selectVal: '8x247',
				keys: [1,2,3,4,5,6,7]
			}
		]
	}
]


const groupList = [
	{
		val: '0',
		status: 0,
		and: 1
	},
	{
		val: '1',
		status: 0,
		and: 3
	},
	{
		val: '2',
		status: 0,
		and: 6
		
	},
	{
		val: '3',
		status: 0,
		and: 10
	},
	{
		val: '4',
		status: 0,
		and: 15
	},
	{
		val: '5',
		status: 0,
		and: 21
	},
	{
		val: '6',
		status: 0,
		and: 28
	},
	{
		val: '7',
		status: 0,
		and: 36
	},
	{
		val: '8',
		status: 0,
		and: 45
	},
	{
		val: '9',
		status: 0,
		and: 55
	},
	{
		val: '10',
		status: 0,
		and: 63
	},
	{
		val: '11',
		status: 0,
		and: 69
	},
	{
		val: '12',
		status: 0,
		and: 73
	},
	{
		val: '13',
		status: 0,
		and: 75
	},
	{
		val: '14',
		status: 0,
		and: 75
	},
	{
		val: '15',
		status: 0,
		and: 73
	},
	{
		val: '16',
		status: 0,
		and: 69
	},
	{
		val: '17',
		status: 0,
		and: 63
	},
	{
		val: '18',
		status: 0,
		and: 55
	},
	{
		val: '19',
		status: 0,
		and: 45
	},
	{
		val: '20',
		status: 0,
		and: 36
	},
	{
		val: '21',
		status: 0,
		and: 28
	},
	{
		val: '22',
		status: 0,
		and: 21
	},
	{
		val: '23',
		status: 0,
		and: 15
	},
	{
		val: '24',
		status: 0,
		and: 10
	},
	{
		val: '25',
		status: 0,
		and: 6
	},
	{
		val: '26',
		status: 0,
		and: 3
	},
	{
		val: '27',
		status: 0,
		and: 1
	}
]


const ments = [
	{
		val: '0',
		status: 0
	},
	{
		val: '1',
		status: 0
	},
	{
		val: '2',
		status: 0
	},
	{
		val: '3',
		status: 0
	},
	{
		val: '4',
		status: 0
	},
	{
		val: '5',
		status: 0
	},
	{
		val: '6',
		status: 0
	},
	{
		val: '7',
		status: 0
	},
	{
		val: '8',
		status: 0
	},
	{
		val: '9',
		status: 0
	}
]

const seven = [
	{
		val: '0',
		status: 0
	},
	{
		val: '1',
		status: 0
	},
	{
		val: '2',
		status: 0
	},
	{
		val: '3',
		status: 0
	},
	{
		val: '4',
		status: 0
	},
	{
		val: '5',
		status: 0
	},
	{
		val: '6',
		status: 0
	},
	{
		val: '7',
		status: 0
	},
	{
		val: '8',
		status: 0
	},
	{
		val: '9',
		status: 0
	},
	{
		val: '10',
		status: 0
	},
	{
		val: '11',
		status: 0
	},
	{
		val: '12',
		status: 0
	},
	{
		val: '13',
		status: 0
	},
	{
		val: '14',
		status: 0
	}
]

const double = [
	{
		val: '00',
		status: 0,
		key: 0
	},
	{
		val: '11',
		status: 0,
		key: 1
	},
	{
		val: '22',
		status: 0,
		key: 2
	},
	{
		val: '33',
		status: 0,
		key: 3
	},
	{
		val: '44',
		status: 0,
		key: 4
	},
	{
		val: '55',
		status: 0,
		key: 5
	},
	{
		val: '66',
		status: 0,
		key: 6
	},
	{
		val: '77',
		status: 0,
		key: 7
	},
	{
		val: '88',
		status: 0,
		key: 8
	},
	{
		val: '99',
		status: 0,
		key: 9
	}
]

const and = [
	{
		val: '1',
		status: 0,
		and: 1
	},
	{
		val: '2',
		status: 0,
		and: 2
		
	},
	{
		val: '3',
		status: 0,
		and: 2
	},
	{
		val: '4',
		status: 0,
		and: 4
	},
	{
		val: '5',
		status: 0,
		and: 5
	},
	{
		val: '6',
		status: 0,
		and: 6
	},
	{
		val: '7',
		status: 0,
		and: 8
	},
	{
		val:'8',
		status: 0,
		and: 10
	},
	{
		val: '9',
		status: 0,
		and: 11
	},
	{
		val: '10',
		status: 0,
		and: 13
	},
	{
		val: '11',
		status: 0,
		and: 14
	},
	{
		val: '12',
		status: 0,
		and: 14
	},
	{
		val: '13',
		status: 0,
		and: 15
	},
	{
		val: '14',
		status: 0,
		and: 15
	},
	{
		val: '15',
		status: 0,
		and: 14
	},
	{
		val: '16',
		status: 0,
		and: 14
	},
	{
		val: '17',
		status: 0,
		and: 13
	},
	{
		val: '18',
		status: 0,
		and: 11
	},
	{
		val: 19,
		status: 0,
		and: 10
	},
	{
		val: '20',
		status: 0,
		and: 8
	},
	{
		val: '21',
		status: 0,
		and: 6
	},
	{
		val: '22',
		status: 0,
		and: 5
	},
	{
		val: '23',
		status: 0,
		and: 4
	},
	{
		val: '24',
		status: 0,
		and: 2
	},
	{
		val: '25',
		status: 0,
		and: 2
	},
	{
		val: '26',
		status: 0,
		and: 1
	}
]

const spanform =[
	{
		val: '0',
		status: 0,
		odds: 10
	},
	{
		val: '1',
		status: 0,
		odds: 54
	},
	{
		val: '2',
		status: 0,
		odds: 96
	},
	{
		val: '3',
		status: 0,
		odds: 126
	},
	{
		val: '4',
		status: 0,
		odds: 144
	},
	{
		val: '5',
		status: 0,
		odds: 150
	},
	{
		val: '6',
		status: 0,
		odds: 144
	},
	{
		val: '7',
		status: 0,
		odds: 126
	},
	{
		val: '8',
		status: 0,
		odds: 96
	},
	{
		val: '9',
		status: 0,
		odds: 54
	}
]


const qiles = [
	{
		val: '01',
		status: 0
	},
	{
		val: '02',
		status: 0
	},
	{
		val: '03',
		status: 0
	},
	{
		val: '04',
		status: 0
	},
	{
		val: '05',
		status: 0
	},
	{
		val: '06',
		status: 0
	},
	{
		val: '07',
		status: 0
	},
	{
		val: '08',
		status: 0
	},
	{
		val: '09',
		status: 0
	},
	{
		val: '10',
		status: 0
	},
	{
		val: '11',
		status: 0
	},
	{
		val: '12',
		status: 0
	},
	{
		val: '13',
		status: 0
	},
	{
		val: '14',
		status: 0
	},
	{
		val: '15',
		status: 0
	},
	{
		val: '16',
		status: 0
	},
	{
		val: '17',
		status: 0
	},
	{
		val: '18',
		status: 0
	},
	{
		val: '19',
		status: 0
	},
	{
		val: '20',
		status: 0
	},
	{
		val: '21',
		status: 0
	},
	{
		val: '22',
		status: 0
	},
	{
		val: '23',
		status: 0
	},
	{
		val: '24',
		status: 0
	},
	{
		val: '25',
		status: 0
	},
	{
		val: '26',
		status: 0
	},
	{
		val: '27',
		status: 0
	},
	{
		val: '28',
		status: 0
	},
	{
		val: '29',
		status: 0
	},
	{
		val: '30',
		status: 0
	}
]

const eight = [
	{
		val: '01',
		status: 0
	},
	{
		val: '02',
		status: 0
	},
	{
		val: '03',
		status: 0
	},
	{
		val: '04',
		status: 0
	},
	{
		val: '05',
		status: 0
	},
	{
		val: '06',
		status: 0
	},
	{
		val: '07',
		status: 0
	},
	{
		val: '08',
		status: 0
	},
	{
		val: '09',
		status: 0
	},
	{
		val: '10',
		status: 0
	},
	{
		val: '11',
		status: 0
	},
	{
		val: '12',
		status: 0
	},
	{
		val: '13',
		status: 0
	},
	{
		val: '14',
		status: 0
	},
	{
		val: '15',
		status: 0
	},
	{
		val: '16',
		status: 0
	},
	{
		val: '17',
		status: 0
	},
	{
		val: '18',
		status: 0
	},
	{
		val: '19',
		status: 0
	},
	{
		val: '20',
		status: 0
	},
	{
		val: '21',
		status: 0
	},
	{
		val: '22',
		status: 0
	},
	{
		val: '23',
		status: 0
	},
	{
		val: '23',
		status: 0
	},
	{
		val: '25',
		status: 0
	},
	{
		val: '26',
		status: 0
	},
	{
		val: '27',
		status: 0
	},
	{
		val: '28',
		status: 0
	},
	{
		val: '29',
		status: 0
	},
	{
		val: '30',
		status: 0
	},
	{
		val: '31',
		status: 0
	},
	{
		val: '32',
		status: 0
	},
	{
		val: '33',
		status: 0
	},
	{
		val: '34',
		status: 0
	},
	{
		val: '35',
		status: 0
	},
	{
		val: '36',
		status: 0
	},
	{
		val: '37',
		status: 0
	},
	{
		val: '38',
		status: 0
	},
	{
		val: '39',
		status: 0
	},
	{
		val: '40',
		status: 0
	},
	{
		val: '41',
		status: 0
	},
	{
		val: '42',
		status: 0
	},
	{
		val: '43',
		status: 0
	},
	{
		val: '44',
		status: 0
	},
	{
		val: '45',
		status: 0
	},
	{
		val: '46',
		status: 0
	},
	{
		val: '47',
		status: 0
	},
	{
		val: '48',
		status: 0
	},
	{
		val: '49',
		status: 0
	},
	{
		val: '50',
		status: 0
	},
	{
		val: '51',
		status: 0
	},
	{
		val: '52',
		status: 0
	},
	{
		val: '53',
		status: 0
	},
	{
		val: '54',
		status: 0
	},
	{
		val: '55',
		status: 0
	},
	{
		val: '56',
		status: 0
	},
	{
		val: '57',
		status: 0
	},
	{
		val: '58',
		status: 0
	},
	{
		val: '59',
		status: 0
	},
	{
		val: '60',
		status: 0
	},
	{
		val: '61',
		status: 0
	},
	{
		val: '62',
		status: 0
	},
	{
		val: '63',
		status: 0
	},
	{
		val: '64',
		status: 0
	},
	{
		val: '65',
		status: 0
	},
	{
		val: '66',
		status: 0
	},
	{
		val: '67',
		status: 0
	},
	{
		val: '68',
		status: 0
	},
	{
		val: '69',
		status: 0
	},
	{
		val: '70',
		status: 0
	},
	{
		val: '71',
		status: 0
	},
	{
		val: '72',
		status: 0
	},
	{
		val: '73',
		status: 0
	},
	{
		val: '74',
		status: 0
	},
	{
		val: '75',
		status: 0
	},
	{
		val: '76',
		status: 0
	},
	{
		val: '77',
		status: 0
	},
	{
		val: '78',
		status: 0
	},
	{
		val: '79',
		status: 0
	},
	{
		val: '80',
		status: 0
	}
]

const spans = [
	{
		val: '1',
		status: 0,
		note: '18'
	},
	{
		val: '2',
		status: 0,
		note: '16'
	},
	{
		val: '3',
		status: 0,
		note: '14'
	},
	{
		val: '4',
		status: 0,
		note: '12'
	},
	{
		val: '5',
		status: 0,
		note: '10'
	},
	{
		val: '6',
		status: 0,
		note: '8'
	},
	{
		val: '7',
		status: 0,
		note: '6'
	},
	{
		val: '8',
		status: 0,
		note: '4'
	},
	{
		val: '9',
		status: 0,
		note: '2'
	}
]

const spansTow = [
	{
		val: '2',
		status: 0,
		note: '8'
	},
	{
		val: '3',
		status: 0,
		note: '14'
	},
	{
		val: '4',
		status: 0,
		note: '18'
	},
	{
		val: '5',
		status: 0,
		note: '20'
	},
	{
		val: '6',
		status: 0,
		note: '20'
	},
	{
		val: '7',
		status: 0,
		note: '18'
	},
	{
		val: '8',
		status: 0,
		note: '14'
	},
	{
		val: '9',
		status: 0,
		note: '8'
	}
]

export default {
	multipleList,
	groupList,
	ments,
	and,
	double,
	seven,
	qiles,
	eight,
	spanform,
	spans,
	spansTow
}