<!--
 * @Descripttion: 往期订单
 * @Date: 2023-12-07 14:38:31
 * @Author: SYC
 * @LastEditors: 靓仔辉
 * @LastEditTime: 2024-01-15 16:09:04
-->
<template>
    <div class="container_component" >
        <div class="always_box">
          <div class="periods_top">
            <div class="df_c_s">
              <el-radio-group 
                v-model="periodsType"
                @input="selectPosition"
              >
                <el-radio-button :label="periodsItem.type" 
                v-for="(periodsItem, periodsIndex) in periodsTopList"
                :key="periodsIndex"
                >
                    {{ periodsItem.label }}
                </el-radio-button>
              </el-radio-group>
              <div class="df">
                <div class="select_time" v-if="periodsType == 'zq' || periodsType == 'lq'">
                  <el-date-picker
                    v-model="selectDate"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    :picker-options="pickerOptions"
                    @change="dateChange"
                    >
                  </el-date-picker>
                </div>
                <!-- 时间备份数据暂时没有 -->
                <!-- <div class="select_code" v-if="periodsType == 'zq' || periodsType == 'lq'">
                  <el-select v-model="backupsVal" placeholder="请选择">
                    <el-option
                      v-for="item in backuosList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div> -->
              </div>
            </div>
          </div>

          <div class="periods_content">
              <div class="content_item" v-if="periodsType == 'zq'">
                <football 
                :outerStyle="false"
                :date="selectDate"
                ref="iaZq"
                ></football>
              </div>
              <div class="content_item" v-else-if="periodsType == 'lq'">
                <basketball :outerStyle="false"
                :date="selectDate"
                ref="iaLq"
                ></basketball>
              </div>
              <div v-else-if="periodsType == 'pl'">
                <arrange ref="iaPl" :outerStyle="false"></arrange>
              </div>
              <div style="height: 100%;" v-else-if="periodsType == 'bd'">
                <beidan :outerStyle="false"></beidan>
              </div>
              <div style="height: 100%;" v-else-if="periodsType == 'pw'">
                <arrFive :outerStyle="false"></arrFive>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import football from '../football/index';
import basketball from '../basketball/index.vue';
import arrange from '../arrange/index.vue'
import beidan from '../beidan/index.vue'
import arrFive from '../arrange/arrFive.vue'
export default {
  components: {
    football,
    basketball,
    arrange,
    beidan,
    arrFive
  },
  props: {
    printHtml: "",
  },
  data() {
    return {
      periodsTopList: [
        {
          label: '竞足',
          code: 0,
          type: 'zq'
        },
        {
          label: '竞篮',
          code: 0,
          type: 'lq'
        },
        {
          label: '北京单场',
          code: 0,
          type: 'bd'
        },
        {
          label: '排列三',
          code: 0,
          type: 'pl'
        },
        {
          label: '排列五',
          code: 0,
          type: 'pw'
        }
      ],
      periodsType: 'zq',
      backupsVal: '11:30',     // 备份数据
      backuosList: [
        {
          value: '11:30',
          label: '11:30备份'
        }, {
          value: '12:30',
          label: '12:30备份'
        }, {
          value: '13:30',
          label: '13:30备份'
        }, {
          value: '14:30',
          label: '14:30备份'
        }, {
          value: '15:30',
          label: '15:30备份'
        }, {
          value: '16:30',
          label: '16:30备份'
        }, {
          value: '17:30',
          label: '17:30备份'
        }, {
          value: '18:30',
          label: '18:30备份'
        }, {
          value: '19:30',
          label: '19:30备份'
        }, {
          value: '20:30',
          label: '20:30备份'
        }, {
          value: '21:30',
          label: '21:30备份'
        }, {
          value: '22:30',
          label: '22:30备份'
        },
      ],    // 备份数据集合
      issueVal: '',
      selectDate: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }
    };
  },
  created() {
   this.selectDate = this.getDayTwo(-1)
  },
  methods: {
    // 获取时间
    getDayTwo(day) {
      var today = new Date();　　
      // var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;　　
      // today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();　　
      var tMonth = today.getMonth();　　
      tMonth = this.doHandleMonth(tMonth + 1)　　
      var tDate = today.getDate();　
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth(month) {　　
		var m = month;　　
		if (month.toString().length == 1) {　　
				m = "0" + month;　　
		}　　
		return m;
	},
  dateChange(e) {
      const dt = new Date(e);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + '').padStart(2, '0');
      const d = (dt.getDate() + '').padStart(2, '0');
      let time = y + '-' + m + '-' + d
      if(this.periodsType == 'zq') {
        this.$refs.iaZq.getPeriodsForm(time);
      }else if(this.periodsType == 'lq') {
        this.$refs.iaLq.getPeriodsForm(time);
      }else if(this.periodsType == 'pl') {
        this.$refs.iaPl.getArrangeNum(time);
      }
  },
  selectPosition() {
    this.selectDate = this.getDayTwo(-1)
  }
  }
};
</script>

<style scoped lang="less">
@import url("../../assets/css/always.css");

.periods_top {
  padding: 15px 0;
  border-bottom: 2px solid #efefef;
}

.select_code {
  width: 120px;
  margin-left: 35px;
}

.periods_content {
  height: calc(100% - 65px);
}

.content_item {
  height: 100%;
}
</style>