<!--
 * @Descripttion: 
 * @Date: 2023-11-12 21:54:20
 * @Author: lwh
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-28 12:01:05
-->
<template>
  <div id="app">
    <div v-if="$route.meta.keepAlive">
      <div>
        <ticketHead></ticketHead>
      </div>
      <div class="ticket_box">
        <router-view></router-view>
      </div>
    </div>
    <div v-else>
     <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ticketHead from '@/components/head/index.vue'
export default {
  components: {
    ticketHead
  },
  name: 'App',
  deforeCreate() {
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  height: 100%;
  width: 100%;
  min-width: 950px;
  min-height: 600px;
}
</style>
