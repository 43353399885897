<template>
  <div class="container_component">
    <div class="detail_box">
      <div class="detail_form">

        <div>
          <el-form ref="form" class="form_flex" :inline="true">
            <el-form-item style="width: 30%;" label="订单号:">
              <div class="text">{{ detailForm.order_code }}</div>
            </el-form-item>
            <el-form-item style="width: 30%;" label="下单时间:">
              <div class="text">{{ detailForm.order_time }}</div>
            </el-form-item>
            <el-form-item style="width: 30%;" label="订单状态:">
              <div class="text">{{ detailForm.win_status}}</div>
            </el-form-item>
          </el-form>
          <el-form ref="form" class="form_flex" :inline="true">
            <el-form-item style="width: 30%;" label="投注信息:">
              <div v-if="detailForm.game_type == 0" class="text">{{ detailForm.Betting_Information }}</div>
              <div v-else class="text">{{ detailForm.detail.period }}</div>
            </el-form-item>
            <el-form-item style="width: 30%;" label="打印时间:">
              <div class="text">{{ detailForm.print_time }}</div>
            </el-form-item>
          </el-form>
        </div>

        <div class="payment_form">
            <div class="payment_title">支付信息</div>
            <div v-if="detailForm.game_type == 0">
              <el-form ref="form" class="form_flex" :inline="true">
                <el-form-item style="width: 30%;" label="过关类型:">
                  <div class="text">{{ detailForm.reach_type }}</div>
                </el-form-item>
                <el-form-item style="width: 30%;" label="注数:">
                  <div class="text">{{detailForm.note}}注</div>
                </el-form-item>
                <el-form-item style="width: 30%;" label="投注倍数:">
                  <div class="text">{{ detailForm.multiple }}</div>
                </el-form-item>
              </el-form>
              <el-form ref="form" class="form_flex" :inline="true">
                <el-form-item style="width: 30%;" label="投注金额:">
                  <div class="text">{{ detailForm.bet_money }}</div>
                </el-form-item>
                <el-form-item style="width: 30%;" label="过关方式:">
                  <div class="text">{{ detailForm.Clearance_mode }}</div>
                </el-form-item>
                <el-form-item style="width: 30%;" label="投注类型:">
                  <div class="text">{{ detailForm.lottery_type }}</div>
                </el-form-item>
              </el-form>
            </div>
            <div v-else>
              <el-form ref="form" class="form_flex" :inline="true">
                <el-form-item style="width: 30%;" label="注数:">
                  <div class="text">{{detailForm.note}}注</div>
                </el-form-item>
                <el-form-item style="width: 30%;" label="投注倍数:">
                  <div class="text">{{ detailForm.multiple }}</div>
                </el-form-item>
                <el-form-item style="width: 30%;" label="投注金额:">
                  <div class="text">{{ detailForm.bet_money }}</div>
                </el-form-item>
              </el-form>
              <el-form ref="form" class="form_flex" :inline="true">
                <el-form-item style="width: 30%;" label="投注类型:">
                  <div class="text">{{ detailForm.lottery_type }}</div>
                </el-form-item>
              </el-form>
            </div>
        </div>

        <div class="detail_table" v-if="detailForm.game_type == 1">
          <el-table
            :data="detailForm.detail.lottery"
            style="width: 100%"
            height="250"
            border
            >
            <el-table-column
              label="类型"
              width="180">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.name }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="odds"
              width="180"
              label="注数">
            </el-table-column>
            
            <el-table-column
              prop="loss"
              label="投注类型">
            </el-table-column>
          </el-table>
        </div>

        <div class="detail_table" v-if="detailForm.game_type == 0">
          <el-table
            :data="detailForm.detail.lottery"
            style="width: 100%"
            height="250"
            border
            >
            <el-table-column
              label="编号"
              width="100">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.week }}  {{ scope.row.issue_num }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="投注项"
              >
              <template slot-scope="scope">
                <span>
                  {{ scope.row.home }} vs {{ scope.row.away }} 
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="title"
              label="投注类型">
            </el-table-column>
            <el-table-column
              prop="num"
              label="投注赔率">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.loss }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailForm: {}
  },
  data() {
    return {
      tableData:[
        {
          note: '周四 001',
          xiang: 'PSV埃因霍温 VS 海伦芬',
          lei: '半全场',
          num: '胜胜@1.390元'
        }
      ]
    }
  },
  methods: {
    setWinStatus(status) {
      let text = "";
      switch (status) {
        case 1:
          text = "未开奖";
          break;
        case 3:
          text = "未中奖";
          break;
        case 3:
          text = "中奖";
          break;
      }
      return text;
    },
  }
}
</script>

<style lang="less" scoped>
.detail_box {
  padding: 25px 15px;
}

.text {
  font-weight: bold;
}
.form_flex {
  display: flex;
  flex-wrap: wrap;
}

.payment_title {
  font-size: 14PX;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
}
</style>