<!--
 * @Descripttion: 我的订单
 * @Date: 2023-12-07 14:38:31
 * @Author: SYC
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-23 14:29:57
-->
<template>
  <div class="container_component" v-loading="loading">
    <div class="top df_c">
      <div class="top_left m_r_15">我的订单</div>
      <div class="top_right">
        <el-form ref="form" :inline="true" :model="formData">
          <el-form-item label="客户名称：">
            <el-input
              v-model="formData.customer_name"
              placeholder="请输入客户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单编号：">
            <el-input
              v-model="formData.order_code"
              placeholder="请输入订单编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单起止时间：">
            <div class="df_c">
              <el-date-picker
                v-model="formData.start_time"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
              <div class="p_side_15">至</div>
              <el-date-picker
                v-model="formData.end_time"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="中奖状态：">
            <el-select
              v-model="formData.win_status"
              placeholder="请选择中奖状态"
            >
              <el-option
                v-for="item in winList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="打印时间：">
            <el-input
              v-model="formData.print_time"
              placeholder="请输入打印时间："
            ></el-input>
          </el-form-item>
          <el-form-item label="票面编号：">
            <el-input
              v-model="formData.face_number"
              placeholder="请输入票面编号："
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="getForm"
              type="primary"
              icon="el-icon-search"
              round
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content">
      <div class="always_box">
        <el-table border :data="tableData" style="width: 100%" height="100%">
          <el-table-column prop="order_code" label="订单ID" width="180">
          </el-table-column>
          <el-table-column prop="lottery_type" label="商品类型" width="180">
          </el-table-column>
          <el-table-column prop="order_time" label="下单时间">
          </el-table-column>
          <el-table-column prop="print_time" label="打印时间">
          </el-table-column>
          <el-table-column prop="bet_money" label="总金额">
          </el-table-column>
          <el-table-column label="中奖状态">
            <template slot-scope="scope">
              <div class="win_status">
                <span :class="setStatusColor(scope.row.win_status)">
                  {{ setWinStatus(scope.row.win_status) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="win_bonus" label="中奖金额"> 
            <template slot-scope="scope">
              <div class="win_status">
                <span>{{ scope.row.win_bonus }}</span>
                <i v-if="scope.row.win_status == 3" @click="editMoney(scope.row)" class="el-icon-edit-outline edit_icon" style=" color: #409eff;"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="customer_name" label="客户名称">
          </el-table-column>
          <el-table-column prop="address" label="操作" width="220">
            <template slot-scope="scope">
              <div>
                <el-button size="mini" 
                @click="onDetail(scope.row.id)" 
                round>查看</el-button>
                <el-button 
                  :disabled="scope.row.win_status != 3" 
                  size="mini" 
                  type="danger"
                  @click="onPrize(scope.row.id)"
                  round>原票兑奖</el-button>
              </div>
              <div class="mt_5">
                <el-button 
                size="mini" 
                type="success" 
                @click="onPrintOrder(scope.row.id)"
                round>打印</el-button>
                <el-button 
                  size="mini" 
                  :disabled="scope.row.win_status != 3" 
                  type="warning" 
                  round
                  @click="printManuscript(scope.row.id)"
                  >已兑奖打印</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>.
    <div class="bottom">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 30]"
        :page-size="formData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :visible.sync="detailVisible"
      :before-close="handleClose"
      title="订单详情"
      >
          <detail :detailForm="detailForm"></detail>
    </el-dialog>
  </div>
</template>

<script>
import detail from './goodsDetail.vue'
import { 
  getOrderSearch,
  getOrderDetails,
  postPrize,
  postPrintOrder,
  postManuscript,
  setBonus_update } from "@/utils/api.js"; // 接口api
export default {
  components: {
    detail
  },
  props: {
    printHtml: "",
  },
  data() {
    return {
      formData: {
        customer_name: "",
        order_code: "",
        start_time: "",
        end_time: "",
        win_status: "",
        print_time: "",
        face_number: "",
        page: 1,
        page_size: 10
      },
      winList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "未开奖",
        },
        {
          value: "3",
          label: "中奖",
        },
        {
          value: "2",
          label: "未中奖",
        },
      ],
      tableData: [],
      total: 400,
      loading: true,
      detailVisible: false,
      detailForm: {}
    };
  },
  mounted() {
    this.getForm();
  },
  methods: {
    getForm() {
      let that = this;
      this.loading = true;
      getOrderSearch(this.formData).then((res) => {
        that.loading = false;
        that.total = res.data.count
        that.tableData = res.data.data;
      });
    },
    setWinStatus(status) {
      let text = "";
      switch (status) {
        case 1:
          text = "未开奖";
          break;
        case 2:
          text = "未中奖";
          break;
        case 3:
          text = "中奖";
          break;
      }
      return text;
    },
    setStatusColor(status) {
      let color = "";
      switch (status) {
        case 1:
          color = "";
          break;
        case 2:
          color = "red";
          break;
        case 3:
          color = "green";
          break;
      }
      return color;
    },
    handleSizeChange(val) {
      this.formData.page_size = val
      this.getForm()
    },
    // 编辑中奖金额
    editMoney(item) {
        this.$prompt('请输入中奖金额', '提示', {
          inputValue: item.win_bonus,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          setBonus_update({
            id: item.id,
            win_bonus: value
          }).then(res => {
            if(res.code == 200) {
              item.win_bonus = value
              this.$message({
                type: 'success',
                message: '修改成功！' 
              });
            }else {
              item.win_bonus = value
              this.$message({
                type: 'error',
                message: res.msg 
              });
            }
          })
        })
    },
    // 原票兑奖
    onPrize(id) {
      const loading = this.$loading({
          lock: true,
          text: '兑奖中请耐心等待...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      postPrize({
        id
      }).then(res => {
        loading.close();
        if(res.code == 200) {
          this.$message({
            type: 'success',
            message: '兑奖成功！' 
          });
        }else {
          this.$message({
            type: 'error',
            message: res.msg 
          });
        }
      })
    },
    printManuscript(id) {
      const loading = this.$loading({
          lock: true,
          text: '打印中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        postManuscript({id}).then(res => {
          loading.close();
          if(res.code == 200) {
            this.$message({
              type: 'success',
              message: '打印成功！' 
            });
          }else {
            this.$message({
              type: 'error',
              message: res.msg 
            });
          }
        })
        
    },
    // 打印
    onPrintOrder(id) {
      const loading = this.$loading({
          lock: true,
          text: '打印中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        postPrintOrder({
          id
        }).then(res => {
          loading.close();
          if(res.code == 200) {
            this.$message({
              type: 'success',
              message: '打印成功！' 
            });
          }else {
            this.$message({
              type: 'error',
              message: res.msg 
            });
          }
        })
    },
    handleCurrentChange(val) {
      this.formData.page = val
      this.getForm()
    },
    onDetail(id) {
      let that = this
      getOrderDetails({id}).then(res => {
        that.detailForm = res.data
        that.detailVisible = true
      })
    },
    handleClose() {
      this.detailVisible = false
    }
  },
};
</script>

<style scoped lang="less">
@import url("../../assets/css/always.css");

.top {
  padding-top: 18px;
  border-bottom: 1px solid #e6e6e6;
  .top_left {
    width: 80px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
  .top_right {
    width: 1200px;
  }
}

.content {
  padding: 15px 0;
  height: calc(100% - 195px);
}

.bottom {
  text-align: right;
}

.red {
  color: red;
}

.green {
  color: green;
}

.edit_icon {
  font-size: 15px;
 
}
</style>