var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"print-wrap"},[_c('div',{staticStyle:{"width":"320px","padding":"0 15px","margin":"0 auto","color":"#000","font-size":"12px"},attrs:{"id":"printBom"}},[_vm._m(0),_vm._m(1),_c('div',{staticStyle:{"padding":"5px 0","height":"300px","overflow":"hidden"}},[_vm._m(2),_vm._m(3),_c('div',{staticStyle:{"font-family":"'HEITI'"}},_vm._l((_vm.spotList),function(spotItem,soptIndex){return _c('div',{key:soptIndex,staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"padding-left":"10px"}},[_vm._v("*")]),_c('div',{staticStyle:{"padding-left":"10px"}},[_vm._v("*")]),_c('div',{staticStyle:{"padding-left":"10px"}},[_vm._v("*")])])}),0)]),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"12px"}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticStyle:{"width":"120px","height":"35px"},attrs:{"src":require("./config/logo.png")}})]),_c('div',{staticStyle:{"text-align":"center","font-family":"'AWARDW'"}},[_vm._v("竞彩足球混合过关")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","font-family":"'AWARDW'"}},[_c('div',[_vm._v("140332700571816307865465")]),_c('div',[_vm._v("32173217")]),_c('div',[_vm._v("3X6361NA")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"5px 0"}},[_c('div',{staticStyle:{"border-top":"1px dashed #000"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-family":"'HEITI1'","display":"flex","justify-content":"space-between","font-weight":"700"}},[_c('div',[_vm._v("过关方式 2x1")]),_c('div',[_vm._v("1倍")]),_c('div',[_vm._v("合计 "),_c('span',{staticStyle:{"font-family":"'HTITI'"}},[_vm._v("2")]),_vm._v("元")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"12px","font-family":"'GGFS'"}},[_c('div',{staticClass:"play_noe"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"margin-right":"5px"}},[_vm._v("第一场")]),_c('div',{staticStyle:{"margin-right":"5px"}},[_vm._v("周三301")]),_c('div',{staticClass:"note"},[_vm._v("预设总分:2285")])]),_c('div',{staticClass:"note_race"},[_vm._v("客队:华盛顿奇才 Vs 主队:底特律活塞")]),_c('div',{staticClass:"note_money"},[_vm._v("小"),_c('span',{staticClass:"test"},[_vm._v("@")]),_vm._v("1.700元")])]),_c('div',{staticClass:"play_noe"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"margin-right":"5px"}},[_vm._v("第一场")]),_c('div',{staticStyle:{"margin-right":"5px"}},[_vm._v("周三301")]),_c('div',{staticClass:"note"},[_vm._v("预设总分:2285")])]),_c('div',{staticClass:"note_race"},[_vm._v("客队:华盛顿奇才 Vs 主队:底特律活塞")]),_c('div',{staticClass:"note_money"},[_vm._v(" 小"),_c('span',{staticClass:"test"},[_vm._v("@")]),_c('span',{staticStyle:{"font-family":"'HTITI'"}},[_vm._v("1.700")]),_vm._v("元 ")])]),_c('div',{staticClass:"play_notes"},[_vm._v("(选项固定奖金额为每1元投注对应的奖金额)")]),_c('div',{staticClass:"play_regular"},[_vm._v(" 本票最高可能固定奖金:"),_c('span',{staticClass:"money_text"},[_vm._v("308.49")]),_vm._v("元 ")]),_c('div',[_vm._v("单倍注数:2x1"),_c('span',{staticStyle:{"font-family":"'HEITI'"}},[_vm._v("*")]),_vm._v("1注;共1注")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"5px 0"}},[_c('div',{staticStyle:{"border-top":"1px dashed #000"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-weight":"'ICOMOON'"}},[_c('div',[_vm._v("感谢您为公益事业贡献 8.82元")]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v("北京路265号")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"left"},[_vm._v("02-019718-101")]),_c('div',{staticClass:"right"},[_vm._v("23/11/29 15:08:53")])]),_c('div',{staticStyle:{"width":"100%"}},[_c('img',{staticStyle:{"width":"100%","height":"60px"},attrs:{"src":require("./config/bderweima.png")}})])])
}]

export { render, staticRenderFns }