<template>
  <div class="container">
     <div class="forms-container">
       <div class="signin-signup" >
         <!-- 账号密码登录 -->
         <form action="#" class="sign-in-form" v-if="loginWay == '0'">
           <div class="input-field">
             <img class="fas fa-user icon_img" :src="require('../../assets/img/login/phone1.png')" alt="">
              <el-input
              class="fas_input"
              placeholder="请输入账号/手机号"
              v-model="loginByAccountForm.username"

              >
               </el-input>
           </div>
           <div class="input-field">
             <img class="fas fa-user icon_img" :src="require('../../assets/img/login/icon-pwd.png')" alt="">
              <el-input
              class="fas_input"
              placeholder="请输入密码"
              v-model="loginByAccountForm.password"
              :type="loginByAccountForm.showPwd? 'text': 'password'"

              >
               </el-input>
               <div class="fas_eyes" @click="loginByAccountForm.showPwd = !loginByAccountForm.showPwd">
                 <img v-if="loginByAccountForm.showPwd" class="fas fa-user icon_eyes"
                 :src="require('../../assets/img/login/icon-eyes2.png')"  alt="">
                 <img v-else class="fas fa-user icon_eyes" :src="require('../../assets/img/login/icon-eyes.png')"  alt="">
               </div>
           </div>

           <el-button class="fas_btn" type="primary" @click="doLogin" >登录</el-button>
         </form>
       </div>
     </div>
     <!-- 左右切换 -->
     <div class="panels-container">
       <div class="panel left-panel pos_pane">
         <div class="content">
         </div>
         <img :src="require('../../assets/img/login/icon-drawing.png')" class="image left_img" alt="" />
         <div class="bottom_left bottom_img" >
           <img :src="require('../../assets/img/login/icon-bottom2.png')" alt="" />
         </div>
       </div>
       <div class="panel right-panel pos_pane">
         <div class="content">
           <p class="scoial-title">登录</p>
           <p class="social-text">欢迎进入                  ！您的账号注册好了吗？</p>
           <p class="social-text">赶紧来登录试试吧！</p>
           <button class="btn transparent" id="sign-in-btn" @click="loginSwitch">
             登录
           </button>
         </div>
         <img :src="require('../../assets/img/login/icon-right.gif')" class="image right_img" alt="" />
         <div class="bottom_right bottom_img right_img">
           <img :src="require('../../assets/img/login/icon-bottom1.png')" alt="" />
         </div>
       </div>
     </div>
   </div>
</template>

<script>
import {onLogin} from '@/utils/api.js'
 export default {
   data() {
     return {
       loginWay: '0',
       loginByAccountForm: {
         username: '',
         password: '',
         showPwd: false
       },
       loginByPhoneForm: {
         phone: '',
         smsCode: ''
       },
       /*注册或修改密码*/
       form: {
         phone: '',
         smsCode: '',
         pwd: '',
         showPwd: false
       },
       sendStatus: false,
       task: null,
       time: 0,
       smsCodeText: '获取验证码',
       registerForm: {
         phone: '',
         smsCode: '',
         pwd: '',
         showPwd: false
       },
       registerTime: 0,
       smsRegisterCodeText: '获取验证码',
       regisetTask: null,
       switchWay: 0
     }
   },
   created() {
      localStorage.removeItem('token');                    
      this.task = setInterval(() => {
       if(this.time > 0) {
         this.time --;

         if(this.time === 0) {
           this.smsCodeText = '获取验证码';
         } else {
           this.smsCodeText = '倒计时' + this.time + 's';
         }
       }
     }, 1000);
      this.regisetTask = setInterval(() => {
       if(this.registerTime > 0) {
         this.registerTime --;

         if(this.registerTime === 0) {
           this.smsRegisterCodeText = '获取验证码';
         } else {
           this.smsRegisterCodeText = '倒计时' + this.registerTime + 's';
         }
       }
     }, 1000);
   },
   destroyed() {
     if(this.task) {
       clearInterval(this.task);
     }
     if(this.regisetTask) {
       clearInterval(this.regisetTask);
     }
     if(this.loginTask) {
       clearInterval(this.loginTask);
     }
   },
   methods: {
     openIndex() {
       this.$router.push({path: '/'});
     },
     //  切换效果
     registerSwitch() {
       this.switchWay = 1
       const container = document.querySelector(".container");
       container.classList.add("sign-up-mode");
       this.loginByAccountForm.account = ''
       this.loginByAccountForm.pwd = ''
       this.loginByAccountForm.showPwd = false
       this.loginByPhoneForm.phone = ''
       this.loginByPhoneForm.smsCode = ''
       this.time = 0
     },
     // 删除效果
     loginSwitch() {
       this.switchWay = 0
       const container = document.querySelector(".container");
       container.classList.remove("sign-up-mode");
       this.registerForm.phone = ''
       this.registerForm.smsCode = ''
       this.registerForm.pwd = ''
       this.registerForm.showPwd = false
       this.registerTime = 0
     },
     // 手机登录
     phoneLogin() {
       this.loginWay = '1'
       this.loginByAccountForm.account= ''
       this.loginByAccountForm.pwd= ''
     },
     // 验证码登录
     smsLogin() {
       this.loginByAccountForm.phone = ''
       this.loginByAccountForm.smsCode = ''
       this.loginWay = '0'
     },
     openRegister() {
       this.$router.push({path: '/register'});
     },
     // 忘记密码
     openChangePwd() {
       this.$router.push({path: '/forget'});
     },
     doLogin() {
      if(!this.loginByAccountForm.username) {
        this.$message.error('请输入帐号/手机号');
        return;
      }
      if(!this.loginByAccountForm.password) {
        this.$message.error('请输入密码');
        return;
      }
      const that = this
      onLogin({
        username: this.loginByAccountForm.username,
        password: this.loginByAccountForm.password
      }).then(res => {
        if(res.code == 200) {
          localStorage.setItem('userName', res.token.name)
          localStorage.setItem('token', res.token.token)
          that.$message.success(res.msg);
          that.$router.replace('/');
        }else {
          this.$message({
              type: 'error',
              message: res.msg
          });
        }
      })
     },
     sendSms() {
       //点击发送按钮，立刻倒计时60秒，再次点击无效，要等待60秒
       if(this.time > 0) {
         return;
       }

       if(!this.loginByPhoneForm.phone) {
         this.$message.error('请输入手机号');
         return;
       }
       if(this.loginByPhoneForm.phone.length !== 11) {
         this.$message.error('手机号必须是11位');
         return;
       }

       this.time = 60;

       //发送登录短信
       this.$http.get('/api/sys/login/sendLoginSms', {
         params: {
           phone: this.loginByPhoneForm.phone
         }
       }).then((response) => {
         if(response.data.success) {
           this.$message.success(response.data.msg);
           //发送短信成功，更新发送状态，避免还没发短信的人，直接登录
           this.sendStatus = true;
         } else {
           this.time = 0;
           this.smsCodeText = '获取验证码';
         }
       });

     },
     // 注册
     doRegister() {
       if(!this.registerForm.phone) {
         this.$message.error('请输入手机号');
         return;
       }
       if(this.registerForm.phone.length !== 11) {
         this.$message.error('手机号必须是11位');
         return;
       }
       if(!this.registerForm.smsCode) {
         this.$message.error('请输入验证码');
         return;
       }
       if(!this.sendStatus) {
         this.$message.error('请先发送短信');
         return;
       }
       if(!this.registerForm.pwd) {
         this.$message.error('请输入密码');
         return;
       }

       //禁止重复提交
       this.disabledBtn = true;

       this.$http.post('/api/sys/login/register', {
         smsCode: this.registerForm.smsCode,
         phone: this.registerForm.phone,
         pwd: this.registerForm.pwd
       }).then((response) => {
         this.disabledBtn = false;
         if(response.data.success) {
           this.$message.success(response.data.msg);
           this.$storage.setShop(response.data.data.shop);
           this.$storage.setUser(response.data.data.user);
           this.$storage.setToken(response.data.data.token);
           this.$storage.setAuths(response.data.data.auths);
           //是否入驻成功
           if(response.data.data.settleIn === '1') {
             this.$router.push({path: '/home'});
           } else {
             this.$router.push({path: '/settleIn'});
           }
         }
       }).catch((error) => {
         this.disabledBtn = false;
       });

     },
     registerSendSms() {
       //点击发送按钮，立刻倒计时60秒，再次点击无效，要等待60秒
       if(this.registerTime > 0) {
         return;
       }

       if(!this.registerForm.phone) {
         this.$message.error('请输入手机号');
         return;
       }
       if(this.registerForm.phone.length !== 11) {
         this.$message.error('手机号必须是11位');
         return;
       }

       this.registerTime = 60;

       this.$http.get('/api/sys/login/sendRegisterSms', {
         params: {
           phone: this.registerForm.phone
         }
       }).then((response) => {
         if(response.data.success) {
           this.$message.success(response.data.msg);
           //发送短信成功，更新发送状态，避免还没发短信的人，直接登录
           this.sendStatus = true;
         } else {
           this.registerTime = 0;
           this.smsRegisterCodeText = '获取验证码';
         }
       });

     }
   }
 }
</script>
<style lang="less" scoped>
 @import url('../../assets/css/login.less');
 .icon_img {
   width: 20px;
   height: 28px;
   margin: auto;
 }
 .fas_input {
   height: 100%;
   border: none;
   width: 80%;
 }
 /deep/.el-input__inner {
     height: 55px !important;
     background-color: #f0f0f0;
     border: none;
     font-size: 18px;
     padding: 0;
   }
 .icon-eye-box {
   width: 70px;
   height: 70px;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
 }
 .fas_eyes {
   height: 55px;
   width: 70px;
   line-height: 62px;
   position: absolute;
   right: 0;
 }
 .fas_sms {
   width: 120px;
   height: 55px;
   background: #1790FE;
   opacity: 1;
   border-radius: 0px 100px 100px 0px;
   font-size: 16px;
   line-height: 55px;
   position: absolute;
   right: 0;
   color: #FFFFFF;
 }
 .icon_eyes {
   width: 25px;
   height: 13px;
   margin: auto;

 }
 .fas_btn {
   width: 100%;
   max-width: 380px;
   height: 55px;
   opacity: 1;
   border-radius: 100px;
   font-size: 20px;
   margin-top: 20px;
   margin-bottom: 10px;
 }
 .fas_auto {
   width: 100%;
   max-width: 380px;
   display: flex;
   margin: 10px 0;
   .icon_choice {
     width: 22px;
     height: 22px;
     line-height: 22px;
   }
   .choice_text {
     font-size: 16px;
     font-weight: 400;
     color: #A1A1A1;
     margin-left: 10px;
   }

 }

 .title_img {
   width: 240px;
   height: 42px;
   margin-bottom: 30px;
   img {
     width: 100%;
     height: 100%;
     cursor: pointer;
   }
 }

 .fas_bottom {
   width: 100%;
   max-width: 380px;
   display: flex;
   margin: 10px 0;
   justify-content: space-between;
   .fas_divs {
     cursor: pointer;
     img {
       display: inline-block;
       vertical-align: middle;
     }
     .fas_bottom_text {
       height: 20px;
       font-size: 16px;
       font-family: Source Han Sans CN;
       font-weight: 400;
       line-height: 32px;
       color: #000000;
       opacity: 0.37;
       display: inline-block;
     }
   }

 }
 .panel {
   .content {
     margin: 0 auto !important;
   }
   .social-text {
     width: 600px;
     height: 30px;
     font-size: 22px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     line-height: 0px;
     color: #FFFFFF;
     opacity: 1;
     margin: 20px 0;
   }
   .scoial-title {
     font-size: 35px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     line-height: 0px;
     color: #FFFFFF;
     opacity: 1;
     text-align: center;
     padding-bottom: 30px;
   }
 }
 .pos_pane {
   position: relative;
   .bottom_img {
     img {
       width: 180px;
       height: 300px;
       opacity: 1;
     }
   }
   .bottom_left {
     position: absolute;
     left: -10px;
     bottom: -50px;
   }
   .bottom_right {
     position: absolute;
     right: -10px;
     bottom: -50px;
   }
 }

</style>


