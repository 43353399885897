/*
 * @Descripttion: 
 * @Date: 2023-12-19 12:52:28
 * @Author: SYC
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-19 13:27:25
 */
const yearFormList = [
  {
    val: 2030
  },
  {
    val: 2029
  },
  {
    val: 2028
  },
  {
    val: 2027
  },
  {
    val: 2026
  },
  {
    val: 2025
  },
  {
    val: 2024
  },
  {
    val: 2023
  },
  {
    val: 2022
  },
]

const monthFormList = [
  {
    label: '12',
    val: 12
  },
  {
    label: '11',
    val: 11
  },
  {
    label: '10',
    val: 10
  },
  {
    label: '09',
    val: 9
  },
  {
    label: '08',
    val: 8
  },
  {
    label: '07',
    val: 7
  },
  {
    label: '06',
    val: 6
  },
  {
    label: '05',
    val: 5
  },
  {
    label: '04',
    val: 4
  },
  {
    label: '03',
    val: 3
  },
  {
    label: '02',
    val: 2
  },
  {
    label: '01',
    val: 1
  }
]

export default {
  yearFormList,
  monthFormList
}