
/**博热优化
*/
function benOptimize(amount, prizeArr){
	var sumF = 0,returnArr = [];
	 for(var j=0;j<prizeArr.length;j++){
	     sumF += Math.ceil(amount/prizeArr[j]);
	     returnArr[returnArr.length] = Math.ceil(amount/prizeArr[j]);
	  }
	  if(sumF - (amount/2) <= 0){//实际注数不足理论注数，默认加到第一注
	     var _k1 = (amount/2) + returnArr[0] - sumF;
	     returnArr[0] = _k1;
	    }else{//实际注数多于理论注数，先设置所有注数为1，再从第一注开始加
	     return prizeOptimize(amount, prizeArr);
	   }
	     return returnArr;
};
/**博冷优化
*/
function coolOptimize(amount, prizeArr){
	var sumF = 0,returnArr = [];
	 for(var j=0;j < prizeArr.length;j++){
	     sumF += Math.ceil(amount/prizeArr[j]);
	     returnArr[returnArr.length] = Math.ceil(amount/prizeArr[j]);
	  }
	  if(sumF - (amount/2) <= 0){//实际注数不足理论注数，默认加到第一注
	     var _k1 = (amount/2) + returnArr[returnArr.length-1] - sumF;
	     returnArr[returnArr.length-1] = _k1;
	    }else{//实际注数多于理论注数，先设置所有注数为1，再从第一注开始加
	     return prizeOptimize(amount, prizeArr);
	   }
	     return returnArr;
};

/**
 * 平均优化
 */
function prizeOptimize(amount, prizeArr) {
	var zs = amount / 2;
	var sumF = 1,
		temF = 0,
		returnArr = [],
		lockSum = 0,
		lockCount = 0;
	for (var j = 0; j < prizeArr.length - 1; j++) {
		sumF += prizeArr[prizeArr.length - 1] / prizeArr[j];
		returnArr[returnArr.length] = prizeArr[prizeArr.length - 1] / prizeArr[j];
	}
	returnArr[returnArr.length] = 1;
	temF = zs / sumF;
	var zzs = 0,
		flag = false;
	for (var m = 0; m < returnArr.length; m++) {

		if (temF * returnArr[m] < 1) {
			returnArr[m] = Math.ceil(temF * returnArr[m]);
		} else {
			returnArr[m] = Math.round(temF * returnArr[m]);
		}

		zzs += returnArr[m];
	}
	//验证注数是否一致
	if (zzs <= zs) {
		//平均分配
		var _ye = zs - zzs;
		while (_ye > 0) {
			for (var k = 0; k < returnArr.length; k++) {
				if (_ye <= 0) {
					break;
				}

				returnArr[k] += 1;
				_ye--;

			}
		}
	} else { //实际注数多于理论注数
		//先从多的里面减去注数，看是否能达到平衡，如若不行，先设置所有注数为1，再从第一注开始加
		var oye = zzs - zs;
		_index = 0;
		for (var _index = 0; oye > 0 && _index < returnArr.length; _index++) {

			if (returnArr[_index] - Math.ceil(amount / prizeArr[_index]) > 0) {
				var unitZs = returnArr[_index] - Math.ceil(amount / prizeArr[_index]);
				if (oye < unitZs) {
					returnArr[_index] = Math.ceil(amount / prizeArr[_index]) + (unitZs - oye);
					oye = 0;
				} else {
					oye -= unitZs;
					returnArr[_index] = Math.ceil(amount / prizeArr[_index]);
				}
			}

		}
		if (oye > 0) {
			if (flag && (lockSum + (prizeArr.length - lockCount)) < zs) {
				sumF = 1, temF = 0, returnArr = [];
				for (var j = 0; j < prizeArr.length - 1; j++) {

					sumF += prizeArr[prizeArr.length - 1] / prizeArr[j];
					returnArr[returnArr.length] = prizeArr[prizeArr.length - 1] / prizeArr[j];

				}

				returnArr[returnArr.length] = multipleCache[prizeArr.length - 1];

				temF = (zs - lockSum) / sumF, zzs = 0;
				for (var m = 0; m < returnArr.length; m++) {

					if (temF * returnArr[m] < 1) {
						returnArr[m] = Math.ceil(temF * returnArr[m]);
					} else {
						returnArr[m] = Math.round(temF * returnArr[m]);
					}

					zzs += returnArr[m];
				}
				if (zzs <= zs) {
					//平均分配
					var _ye = zs - zzs;
					while (_ye > 0) {
						for (var k = 0; k < returnArr.length; k++) {
							if (_ye <= 0) {
								break;
							}
							if (!lockMatchCache[k]) {
								returnArr[k] += 1;
								_ye--;
							}
						}
					}
				} else {
					var _ye = zzs - zs;
					while (_ye > 0) {
						for (var k = 0; k < returnArr.length; k++) {
							if (_ye <= 0) {
								break;
							}
							if (!lockMatchCache[k] && returnArr[k] > 1) {
								returnArr[k] -= 1;
								_ye--;
							}
						}
					}
				}
			} else {
				var _ye = zs - returnArr.length;
				var tempArr = [];
				for (var k = 0; k < returnArr.length; k++) {
					tempArr[tempArr.length] = 1;

				}
				var _index = 0;
				while (_ye != 0) {
					if (_ye >= returnArr[_index]) {
						tempArr[_index] = returnArr[_index];
						_ye -= (returnArr[_index] - 1);
					} else {
						tempArr[_index] = _ye + 1;
						_ye = 0;
					}
					_index++;
				}
				returnArr = tempArr;
			}
		}
	}
	//最后验证是否有不盈利的单，如有进行平衡处理
	var _low = _high = 0;
	for (var r = 0; r < returnArr.length; r++) {

		if (returnArr[r] - Math.ceil(amount / prizeArr[r]) < 0) {
			_low += (Math.ceil(amount / prizeArr[r]) - returnArr[r]);
		} else if (returnArr[r] - Math.ceil(amount / prizeArr[r]) > 0) {
			_high += (returnArr[r] - Math.ceil(amount / prizeArr[r]));
		}

	}
	if (_low > 0 && _low <= _high) {
		for (var r = 0; r < returnArr.length; r++) {
			if (_low == 0)
				break;

			if (returnArr[r] - Math.ceil(amount / prizeArr[r]) > 0) {
				if ((returnArr[r] - Math.ceil(amount / prizeArr[r])) <= _low) {
					_low -= (returnArr[r] - Math.ceil(amount / prizeArr[r]));
					returnArr[r] = Math.ceil(amount / prizeArr[r]);
				} else {
					returnArr[r] -= _low;
					_low = 0;
				}
			}

		}
		for (var r = 0; r < returnArr.length; r++) {

			if (returnArr[r] - Math.ceil(amount / prizeArr[r]) < 0) {
				returnArr[r] = Math.ceil(amount / prizeArr[r]);
			}

		}
	}
	
	return returnArr;
};

/* 排列组合 */
function calculate(arr,num){
	//实现组合
	var r = [];      //r中存放的所有可能的组合
	(function f(t,a,n){
		if (n==0) return r.push(t);
		for (var i = 0, l=a.length; i <= l-n; i++) {
			f(t.concat(a[i]),a.slice(i+1),n-1)
		};
	})([],arr,num)
	return r;
}

function filterMon(key) {
	let maxAmount = 0
	switch (key){
		case 1:
			maxAmount = 50000
			break;
		case 2:
			maxAmount = 100000
			break;
		case 3:
			maxAmount =	100000
			break;
		case 4:
			maxAmount = 250000
			break;
		case 5:
			maxAmount = 250000
			break;
		default: 
			maxAmount = 500000
			break;
	}
	return maxAmount
}

function getPay(arr, key){
	let maxAmount = filterMon(key)
	if (!Array.isArray(arr)) {return};
	var Y = this, datas = Y.bdList, maxMoney=[], num=[], sendData=[];
	for (var i = 0; i < arr.length; i++) {
		var len=mon=1;
		//arr[i]中存放的是选中比赛的组合[[1,2,3],[1,3,4]]
		//arr[i][j]是对应比赛的索引，注数就等于arr[i][j]选中的彩果相乘，因为是n串1，最高奖金就是这里面最大值
		for (var j = 0; j < arr[i].length; j++) {
			var xh = arr[i][j];
			var max = xh["pl"].sort(function(a,b){return b-a});
			len *= xh["pl"].length;
			mon *= max[0];
		};
		num.push(len);
		if(key) {
			if(mon > maxAmount) {
				mon = maxAmount
			}
		}
		maxMoney.push(mon);
	};
	var data1=data2=0;
    for (var i = 0; i < maxMoney.length; i++) {
        data1 += maxMoney[i];
    };
    for (var i = 0; i < num.length; i++) {
        data2 += num[i];
    };
    sendData.push(data2);//注数
    sendData.push(data1);//最大数值
    return sendData;
}



function filterVal(arr, type) {
	let newArr = []
	arr.map(items => {
		let bas = []
		items.forEach(item => {
			if(type == 'ang') {
				let val = item.week + item.issue_num + '|' + item.key
				if(item.num) {
					val = val + '(' + Number(item.num) + ')'
				}
				bas.push(val)
			}else if(type == 'fil') {
				let val = ''
				if (item.title == '让分客胜') {
					val = item.week + ',' + item.issue_num + ',' + '客胜' + item.key + ',' + item.value
				}else if(item.title == '让分主胜') {
					val = item.week + ',' + item.issue_num + ',' + '主胜' + item.key + ',' + item.value
				}else {
					val = item.week + ',' + item.issue_num + ',' + item.key + ',' + item.value
				}
				if(item.num) {
					val = val + ',' +  item.num
				}
				bas.push(val)
			}
			else {
				bas.push(item.value)
			}
		})
		newArr.push(bas)
	})
	return newArr
}

function minCal(arr, num, type) {
	var r = [];      //r中存放的所有可能的组合
	(function f(t,a,n){
		if (n==0) return r.push(t);
		for (var i = 0, l=a.length; i <= l-n; i++) {
			f(t.concat(a[i]),a.slice(i+1),n-1)
		};
	})([],arr,num)
	
	let codeArr = r.filter(item => {
		return !filterCal(item)
	})
	let vals = filterVal(codeArr,type)
	return vals;
}

function filterCal(arr) {
	 var array = [];
	arr.forEach((item) => {
	  if (item) {
	   array.push(item.id);
	  }
	});
	if((new Set(array)).size != arr.length){
		return true;
	}
	  return false;
}

 function sortdatalist(Name) { 
	var datalist = (object1, object2) => {
	  var value1 = object1[Name]
	  var value2 = object2[Name]
		  if (value1 < value2) {
			  return -1        
		  } else if (value1 > value2) { 
			  return 1
		  } else { 
			  return 0 
		  }      
	  }      
	return datalist 
}


module.exports = {
	prizeOptimize,
	benOptimize,
	coolOptimize,
	calculate,
	getPay,
	minCal,
	sortdatalist
}