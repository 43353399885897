/*
 * @Descripttion: 
 * @Date: 2023-11-12 21:54:20
 * @Author: lwh
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-12 03:05:36
 */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "./assets/font/font.css";
import './assets/css/reset.css'
import less from 'less'
import print from './utils/print.js'
import htmlToPdf from './utils/htmlToPdf.js'
// 使用Vue.use()方法就会调用工具方法中的install方法
Vue.use(htmlToPdf)

Vue.use(print)

Vue.use(less)

Vue.use(ElementUI, {size: 'small', zIndex: 3000});


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
