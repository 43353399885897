<!--
 * @Descripttion: 
 * @Date: 2023-11-12 21:54:20
 * @Author: lwh
 * @LastEditors: HUI
 * @LastEditTime: 2023-12-28 12:17:50
-->
<template>
    <div>
        <div class="ticket_head df_c_s">
            <div class="left df_c">
                <div class="head_icon">
                    <img class="icon_img"  src="@/assets/img/head_icon.png" alt="">
                </div>
                <div class="head_menu">
                    <el-menu 
                        class="el-menu-demo"
                        :default-active="$route.name"
                        background-color="#c40c0c"
                        active-text-color="#b5ec1d"
                        text-color="#fff"
                        mode="horizontal"
                        >
                        <el-menu-item 
                            :index="menuItem.codeName" 
                            v-for="(menuItem, menuIndex) in menuList"
                            :key="menuIndex"
                            @click="handleSelect(menuItem)"
                            >
                                {{menuItem.name}}
                            </el-menu-item>
                    </el-menu>
                </div>
            </div>
            <div class="right df">
                <div class="right_uset df_c m_r_15">
                    <img class="right_pir_img m_r_10" src="@/assets/img/user_pir.png" alt="">

                    <el-dropdown>
                        <div class="right_uset_name">
                            <span>{{ userName }}</span>
                            <i class="el-icon-caret-bottom"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown" >
                            <el-dropdown-item @click.native="revampName">修改店铺</el-dropdown-item>
                            <el-dropdown-item @click.native="revampPassword">修改密码</el-dropdown-item>
                            <el-dropdown-item @click.native="revampOpinion">意见反馈</el-dropdown-item>
                            <el-dropdown-item @click.native="revampApply">关于应用</el-dropdown-item>
                            <el-dropdown-item @click.native="logup" divided>退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="right_operate df_c">
                    <!-- <i class="el-icon-link operate_icon"></i> -->
                    <i @click="onRefresh" class="el-icon-refresh operate_icon"></i>
                    <i @click="screen" class="el-icon-full-screen operate_icon"></i>
                    <!-- <i @click="minScreen" class="el-icon-minus operate_icon"></i> -->
                    <i @click="logup" class="el-icon-switch-button operate_icon"></i>
                </div>
            </div>
        </div>

        <el-dialog
            title="修改密码"
            :visible.sync="passwordIsible"
            width="500px"
            center>
                <el-form ref="passwordForm" :rules="rules" :model="passwordForm" label-width="120px">
                    <el-form-item  prop="password" label="登录密码：">
                        <el-input show-password size="medium" style="width: 300px;" v-model="passwordForm.password"></el-input>
                    </el-form-item>
                    <el-form-item prop="new_password" label="确认密码：">
                        <el-input show-password size="medium" style="width: 300px;" v-model="passwordForm.new_password"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="passwordIsible = false">取 消</el-button>
                    <el-button type="primary" @click="submitForm('passwordForm')">确 定</el-button>
                </span>
        </el-dialog>

    </div>
</template>
<script>
    import {updateName, updatePassword} from "@/utils/api.js";
    export default {
        name: "homeHead",
        data(){
            return{
                menuList: [
                    {
                        name: '首页',
                        type: '1',
                        status: 0,
                        routerName: '/',
                        codeName: 'home'
                    },
                     {
                        name: '竞足',
                        type: '2',
                        status: 0,
                        routerName: '/football',
                        codeName: 'football'
                    },
                    {
                        name: '竞篮',
                        type: '3',
                        routerName: '/basketball',
                        status: 0,
                        codeName: 'basketball'

                    },
                    {
                        name: '北京单场',
                        routerName: '/beidan',
                        type: '4',
                        status: 0,
                        codeName: 'beidan'
                    },
                    {
                        name: '排列三',
                        type: '5',
                        routerName: '/arrange',
                        status: 0,
                        codeName: 'arrange'

                    },
                    {
                        name: '排列五',
                        type: '5',
                        routerName: '/arrFive',
                        status: 0,
                        codeName: 'arrFive'

                    },
                    {
                        name: '往期竞猜',
                        type: '6',
                        routerName: '/periods',
                        status: 0,
                        codeName: 'periods'
                    },
                    {
                        name: '我的订单',
                        type: '7',
                        routerName: '/goods',
                        status: 0,
                        codeName: 'goods'
                    },
                     {
                        name: '设置',
                        type: '8',
                        routerName: '/setup',
                        status: 0,
                        codeName: 'setup'
                    }
                ],
                fit: 'contain',
                activeColor: '#409EFF',
                userName: '',
                passwordIsible: false,
                passwordForm: {
                    password: '',
                    new_password: ''
                },
                rules: {
                    password: [
                        { required: true, message: '请输入登录密码', trigger: 'blur' },
                    ],
                    new_password: [
                        { required: true, message: '请输入确认密码', trigger: 'blur' },
                    ]
                },
                fullscreen: false
            }
        },
        created() {
            this.userName = localStorage.getItem('userName')
        },
        methods: {
            // 刷新界面
            onRefresh() {
                location.reload();
            },
            revampApply() {
                this.$alert('当前版本1.0.0(正式版)', '关于应用', {
                    confirmButtonText: '确定',
                    callback: action => {
                    }
                });
            },
            revampOpinion() {
                this.$prompt('', '意见反馈', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    roundButton: true
                }).then(({ value }) => {
                    if(value == null || value == '') {
                        this.$message({
                            type: 'error',
                            message: '意见反馈不能为空！'
                        });
                        return
                    }
                    this.$message({
                        type: 'success',
                        message: '感谢您提交的宝贵意见！'
                    });
                }).catch(() => {
                       
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    updatePassword(this.passwordForm).then(res => {
                        if(res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '修改成功!请重新登陆'
                            });
                            setTimeout(() => {
                                sessionStorage.clear();
                                this.$router.replace("/login");
                            }, 1000);
                        }else {
                            this.$message({
                                type: 'error',
                                message: res.msg
                            });
                        }
                    })
                } else {
                    return false;
                }
                });
            },
            handleSelect(item) {
                this.$router.push({path: item.routerName});
            },
            revampName() {
                this.$prompt('', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    roundButton: true
                }).then(({ value }) => {
                    if(value == null || value == '') {
                        this.$message({
                            type: 'error',
                            message: '名字不能为空！'
                        });
                        return
                    }
                    updateName({
                        name: value
                    }).then(res => {
                        this.userName = value
                        localStorage.setItem('userName', value)
                        this.$message({
                            type: 'success',
                            message: '修改成功'
                        });
                    })
                }).catch(() => {
                       
                });
            },
            revampPassword() {
                this.passwordIsible = true
            },
            logup() {
                this.$confirm('即将退出，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.$message({
                            type: 'success',
                            message: '退出成功!'
                        });
                        setTimeout(() => {
                            sessionStorage.clear();
                            this.$router.replace("/login");
                        }, 1000);
                    }).catch(() => {
                         
                    });
            },
            screen() {
                document.body.requestFullscreen();
                document.exitFullscreen();
            }
        }
    }
</script>
<style lang="less" scoped>
    @import url('./head.css');
    .el-menu {
        border: none !important;
    }
    /deep/ .el-menu-item {
        padding: 0 15px;
    }
</style>